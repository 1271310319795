import { customElement } from 'lit/decorators';
import { css } from 'lit';
import AtaButton, { ataButtonStyles } from './AtaButton';

declare global {
    interface HTMLElementTagNameMap {
        'ata-filled-button': AtaFilledButton;
    }
}

@customElement('ata-filled-button')
export default class AtaFilledButton extends AtaButton {
    static styles = [
        ataButtonStyles,
        css`
            a,
            button {
                ---ataButton--backgroundColor: var(--ata--button--color);
                ---ataButton--foregroundColor: var(--ata--button--onColor);
                ---ataButton--borderColor: var(--ata--button--color);
            }
        `,
    ];
}
