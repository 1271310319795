import { Html } from '../../Application';
import { html } from 'lit';
import Icon from '../Atoms/Icon';
import '../Molecules/AtaNavigationItem';
import { navigation, NavigationItems } from './Navigation';
import { Route } from '../../Route';

export default function vehicleNavigation(
    isExpanded: boolean,
    activeRoute: Route,
    vehicleId: string,
    onNavigate: (href: string) => void,
): Html {
    return navigation({
        isExpanded,
        activeRoute,
        onNavigate,
        header: html` <ata-navigation-header>
            <span slot="icon">${Icon.auto()}</span>
            <span slot="title">AutoTrade Advisor</span>
        </ata-navigation-header>`,
        items: navigationItems(vehicleId),
    });
}

function navigationItems(vehicleId: string): NavigationItems {
    return [
        {
            id: 'VehicleDashboard',
            label: 'Dashboard Auto',
            href: `/voertuig/${vehicleId}/dashboard`,
            icon: Icon.dashboard(),
        },
        {
            label: "Auto's vergelijken",
            icon: Icon.concurrentie(),
            items: [
                {
                    id: 'VehicleComparisonOverview',
                    label: 'Overzicht',
                    href: `/voertuig/${vehicleId}/vergelijk/overzicht`,
                },
                {
                    id: 'VehicleComparisonPriceAnalysis',
                    label: 'Prijsanalyse',
                    href: `/voertuig/${vehicleId}/vergelijk/prijsanalyse`,
                },
                {
                    id: 'VehicleComparisonPriceHistory',
                    label: 'Prijshistorie',
                    href: `/voertuig/${vehicleId}/vergelijk/prijshistorie`,
                },
                {
                    id: 'VehicleComparisonGeographic',
                    label: 'Geografisch',
                    href: `/voertuig/${vehicleId}/vergelijk/geografisch`,
                },
            ],
        },
        {
            label: 'Bereik',
            icon: Icon.bereik(),
            items: [
                {
                    id: 'VehicleReachOverview',
                    label: 'Overzicht',
                    href: `/voertuig/${vehicleId}/bereik/overzicht`,
                },
                {
                    id: 'VehicleReachPriceEffect',
                    label: 'Prijseffect',
                    href: `/voertuig/${vehicleId}/bereik/prijseffect`,
                },
            ],
        },
        {
            id: 'VehicleSettings',
            label: 'Eigenschappen',
            href: `/voertuig/${vehicleId}/settings`,
            icon: Icon.settings(),
        },
        {
            id: 'NotFound',
            label: 'Terug naar voorraadlijst',
            href: '/voorraad',
            icon: Icon.prev(),
        },
    ];
}
