import { customElement } from 'lit/decorators';
import { css, html, LitElement, TemplateResult } from 'lit';

declare global {
    interface HTMLElementTagNameMap {
        'ata-big-heading': AtaBigHeading;
    }
}

@customElement('ata-big-heading')
export default class AtaBigHeading extends LitElement {
    static styles = css`
        :host {
            font-size: 1.5em;
            line-height: 1.5em;
            margin: 0;
        }
    `;

    render(): TemplateResult {
        return html`<slot></slot>`;
    }
}
