import equal from 'fast-deep-equal/es6';

export class Signal<T> {
    private internalValue: T;
    private listeners: Array<(value: T) => void> = [];

    constructor(initialValue: T) {
        this.internalValue = initialValue;
    }

    get value(): T {
        return this.internalValue;
    }

    set(newValue: T): void {
        if (equal(this.internalValue, newValue)) return;
        this.internalValue = newValue;
        this.listeners.forEach((listener) => listener(newValue));
    }

    subscribe(listener: (value: T) => void): void {
        this.listeners.push(listener);
    }
}
