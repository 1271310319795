import * as Result from './Result';

type Idle = { type: 'Idle' };

type Loading<T> = { type: 'Loading'; previousValue?: T };

type Loaded<T> = { type: 'Loaded'; value: T };

type Failed<T> = { type: 'Failed'; previousValue?: T };

export type Async<T> = Idle | Loading<T> | Loaded<T> | Failed<T>;

export function init<T>(): Async<T> {
    return { type: 'Idle' };
}

function loading<T>(previousValue?: T): Async<T> {
    return { type: 'Loading', previousValue };
}

function loaded<T>(value: T): Async<T> {
    return { type: 'Loaded', value };
}

function failed<T>(previousValue?: T): Async<T> {
    return { type: 'Failed', previousValue };
}

export function isIdle<T>(async: Async<T>): async is Idle {
    return async.type === 'Idle';
}

export function isLoading<T>(async: Async<T>): async is Loading<T> {
    return async.type === 'Loading';
}

export function isLoaded<T>(async: Async<T>): async is Loaded<T> {
    return async.type === 'Loaded';
}

export function isFailed<T>(async: Async<T>): async is Failed<T> {
    return async.type === 'Failed';
}

export function markAsLoading<T>(async: Async<T>): Async<T> {
    switch (async.type) {
        case 'Idle':
            return loading();
        case 'Loading':
            return async;
        case 'Loaded':
            return loading(async.value);
        case 'Failed':
            return loading(async.previousValue);
    }
}

export function markAsLoaded<T>(_: Async<T>, value: T): Async<T> {
    return loaded(value);
}

export function markAsFailed<T>(async: Async<T>): Async<T> {
    switch (async.type) {
        case 'Idle':
            return failed();
        case 'Loading':
            return failed(async.previousValue);
        case 'Loaded':
            return failed(async.value);
        case 'Failed':
            return async;
    }
}

//---- Mapping ----//

export function match<T, R>(
    async: Async<T>,
    callback: {
        Idle: () => R;
        Loading: (previousValue?: T) => R;
        Loaded: (value: T) => R;
        Failed: (previousValue?: T) => R;
    },
): R {
    switch (async.type) {
        case 'Idle':
            return callback.Idle();
        case 'Loading':
            return callback.Loading(async.previousValue);
        case 'Loaded':
            return callback.Loaded(async.value);
        case 'Failed':
            return callback.Failed(async.previousValue);
    }
}

export function value<T>(result: Async<T>): T | undefined {
    return match(result, {
        Idle: () => undefined,
        Loading: (previousValue) => previousValue,
        Loaded: (value) => value,
        Failed: (previousValue) => previousValue,
    });
}

export function map<T, R>(async: Async<T>, fn: (value: T) => R): Async<R> {
    return match(async, {
        Idle: () => init(),
        Loading: (previousValue) =>
            loading(previousValue ? fn(previousValue) : undefined),
        Loaded: (value) => loaded(fn(value)),
        Failed: (previousValue) =>
            failed(previousValue ? fn(previousValue) : undefined),
    });
}

type MappedValues<T, E> = T extends [Async<infer First>, ...infer Rest]
    ? [First, ...MappedValues<Rest, E>]
    : [];

export function mapN<T extends Array<Async<unknown>>, R, E>(
    results: [...T],
    fn: (...args: MappedValues<T, E>) => R,
): Async<R> {
    if (results.length === 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return loaded(fn() as R);
    }

    const [first, ...rest] = results;

    return andMap(
        first,
        mapN(rest as [...T], (...restArgs: MappedValues<T, E>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return (firstArg: unknown) => fn(firstArg as E, ...restArgs);
        }) as Async<(value: unknown) => R>,
    );
}

function andMap<T, R>(
    wrappedValue: Async<T>,
    wrappedFunction: Async<(value: T) => R>,
): Async<R> {
    if (isLoaded(wrappedValue) && isLoaded(wrappedFunction)) {
        return loaded(wrappedFunction.value(wrappedValue.value));
    }

    if (isFailed(wrappedFunction)) {
        return failed();
    }

    if (isFailed(wrappedValue)) {
        return failed();
    }

    if (isLoading(wrappedFunction)) {
        return loading();
    }

    if (isLoading(wrappedValue)) {
        return loading();
    }

    return init();
}

//---- Result ----//

export function fromResult<T>(
    result: Result.Result<T, unknown>,
    async?: Async<T>,
): Async<T> {
    return Result.match(result, {
        Ok: (value) => loaded(value),
        Err: () => (async ? markAsFailed(async) : failed()),
    });
}
