import { Html } from '../../Application';
import { html } from 'lit';
import '../Atoms/AtaNumberInput';
import AtaNumberInput from '../Atoms/AtaNumberInput';

export default function numberInput(config: {
    name: string;
    value: string;
    placeholder?: string;
    autocomplete?: string;
    errorMessage?: string;
    isDisabled?: boolean;
    onChange?: (value: string) => void;
    debounce?: {
        duration: number;
        onChange: (value: string) => void;
    };
}): Html {
    const isDisabled = config.isDisabled === true;
    const isReadonly =
        config.onChange === undefined &&
        config.debounce === undefined &&
        !isDisabled;

    const onChange =
        config.onChange !== undefined
            ? (event: Event): void => {
                  config.onChange!((event.target as AtaNumberInput).value);
              }
            : (): void => {};

    const onDebouncedChange =
        config.debounce !== undefined
            ? (event: CustomEvent): void => {
                  config.debounce!.onChange(
                      (event.target as AtaNumberInput).value,
                  );
              }
            : (): void => {};

    return html`<ata-number-input
        name="${config.name}"
        value="${config.value}"
        autocomplete="${config.autocomplete ?? 'off'}"
        placeholder="${config.placeholder}"
        error-message="${config.errorMessage}"
        debounce-duration=${config.debounce?.duration}
        ?disabled="${isDisabled}"
        ?readonly="${isReadonly}"
        @input=${onChange}
        @debouncedInput=${onDebouncedChange}
    ></ata-number-input>`;
}
