import { Html } from '../../Application';
import { css, html, nothing } from 'lit';
import '../Atoms/AtaBigHeading';
import '../Layouts/AtaHorizontalLayout';
import { declareStyle } from '../Util';

declareStyle(css`
    ata-loading-page {
        inset: 0;
        display: grid;
        place-items: center;
        place-content: center;
        background: white;
        z-index: 1000;
        block-size: 20dvb;

        img {
            block-size: 15rem;
            margin-block: var(--dimensions--spacing--medium);
        }
    }

    ata-loading-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        row-gap: var(--dimensions--spacing--large);
        padding: var(--dimensions--spacing--large);

        &[fullscreen] {
            position: fixed;
            inset: 0;
            z-index: 1;
            background: white;
            block-size: 100dvb;
            inline-size: 100dvi;
        }

        #loading-logo {
            width: 50rem;
            max-width: 100%;
            height: auto;
            padding: 0 2rem;
        }

        #loading-loader {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: var(--dimensions--spacing--medium);
        }

        #loading-loader > span {
            display: block;
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
            animation-fill-mode: both;
            animation: ata-loading-three-dots 1.8s infinite ease-in-out;
            background-color: var(--color--secondary);
            scale: 0;
        }

        #loading-loader > span:nth-child(1) {
            animation-delay: -0.32s;
        }

        #loading-loader > span:nth-child(3) {
            animation-delay: 0.32s;
        }
    }

    @keyframes ata-loading-three-dots {
        0%,
        80%,
        100% {
            scale: 0;
        }
        40% {
            scale: 1;
        }
    }
`);

export function loadingPage(description: Html = nothing): Html {
    return html`
        <ata-loading-page>
            <img src="/assets/images/loading.gif" alt="Loading" />
            ${description}
        </ata-loading-page>
    `;
}

export function loadingLogo(): Html {
    return html`
        <ata-loading-logo>
            <img
                id="loading-logo"
                loading="eager"
                src="/assets/images/autotrack-analytics.png"
            />
            <div id="loading-loader">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </ata-loading-logo>
    `;
}
