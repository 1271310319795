import {
    AnalysisCostBenefit,
    AnalysisLeadPerformance,
    AnalysisMakeModel,
    AnalysisMakeModelTab,
    AnalysisOverview,
    ComparisonAdvicePrice,
    ComparisonDaysInStock,
    ComparisonGeographic,
    ComparisonGeographicTab,
    CompetitionProfile,
    CompetitionProfileDealer,
    CompetitionProfileDraft,
    Dealer,
    DealerDashboard,
    DealerDashboardSorting,
    DealerLeads,
    DealerLeadsSorting,
    DealerProfileFilters,
    DealerReach,
    DeviceLayout,
    NotificationSettings,
    Prospects,
    Scenario,
    ScenarioDraft,
    ScenarioSnippet,
    SettingsCompetingCompaniesProfileView,
    SettingsStock,
    SettingsStockForm,
    SortDirection,
    TaxationSettings,
    Vehicle,
    VehicleComparisonGeographic,
    VehicleComparisonPriceHistory,
    VehicleCompetition,
    VehicleCompetitionStatus,
    VehicleDashboard,
    VehicleReachOverview,
    VehicleReachPriceEffect,
    VehicleReachPriceEffectType,
    VehicleSettings,
} from './Model';
import { Route } from './Route';
import { Result } from './Result';
import { ApplicationError } from './Errors';
import { DateRange } from './DateRange';

export type Msg =
    | { type: 'DeviceLayout'; payload: DeviceLayout }
    | { type: 'OnRoute'; payload: Route }
    | { type: 'Navigate'; payload: string }
    | { type: 'NavigateBack' }
    // App
    | { type: 'ToggleNavigation' }
    | {
          type: 'Application_LoginWithToken';
          payload: {
              token: string;
              dealer?: string;
              ma?: string;
              session?: string;
              reference?: string;
              type?: string;
              master: boolean;
          };
      }
    | {
          type: 'Application_IncomingLogout';
          payload: {
              isSuccessful: boolean;
          };
      }
    | {
          type: 'Incoming_Token';
          payload: {
              accessToken: string;
              expiration: Date;
              isMasterAccount: boolean;
          };
      }
    | {
          type: 'Incoming_Session';
          payload: Result<
              {
                  accessToken: string;
                  accountName: string;
                  accountType: 'Dealer' | 'Group' | 'Holding';
                  isPremiumAccount: boolean;
                  isMasterAccount: boolean;
                  stockCount: { total: number; selected: number };
                  lastUpdate: Date;
              },
              ApplicationError
          >;
      }
    | {
          type: 'Incoming_Vehicle';
          payload: Result<Vehicle, ApplicationError>;
      }
    | {
          type: 'Incoming_CompetingDealerProfiles';
          payload: Result<CompetitionProfile[], ApplicationError>;
      }
    | {
          type: 'Incoming_DaysInStockGoal';
          payload: Result<number, ApplicationError>;
      }
    | {
          type: 'Incoming_Dealers';
          payload: Result<Dealer[], ApplicationError>;
      }
    | {
          type: 'Incoming_DealerFilter';
          payload: Result<Record<Dealer['id'], boolean>, ApplicationError>;
      }
    | {
          type: 'Incoming_StockSelection';
          payload: Result<
              {
                  total: number;
                  selected: number;
              },
              ApplicationError
          >;
      }
    // TaxationWidget
    | { type: 'TaxationWidget_OnInputLicensePlate'; payload: string }
    | { type: 'TaxationWidget_OnInputMileage'; payload: string }
    | { type: 'TaxationWidget_OnSubmit' }
    | { type: 'TaxationWidget_OnClearErrorMessage' }
    | {
          type: 'TaxationWidget_IncomingSubmit';
          payload: Result<{ id: string }, ApplicationError>;
      }
    // Login
    | { type: 'Login_OnInputUsername'; payload: string }
    | { type: 'Login_OnInputPassword'; payload: string }
    | { type: 'Login_OnInputCaptcha'; payload: string }
    | { type: 'Login_OnSubmit' }
    | {
          type: 'Login_IncomingAuthentication';
          payload: Result<
              {
                  accessToken: string;
                  expiration: Date;
              },
              ApplicationError
          >;
      }
    // Dashboard
    | {
          type: 'Dashboard_IncomingDealerDashboard';
          payload: Result<DealerDashboard, ApplicationError>;
      }
    | {
          type: 'Dashboard_OnSortStockSummary';
          payload: DealerDashboardSorting;
      }
    | { type: 'Dashboard_OnToggleInfoBlock' }
    | { type: 'Dashboard_OnPrint' }
    | { type: 'Dashboard_OnSelectDealer'; payload: string }
    | { type: 'Dashboard_OnSelectAllDealers' }
    // Stock
    | {
          type: 'Stock_Incoming';
          payload: Result<
              { vehicles: Vehicle[]; totalVehicles: number },
              ApplicationError
          >;
      }
    | { type: 'Stock_OnDownloadExcel' }
    | { type: 'Stock_OnToggleInfoBlock' }
    | { type: 'Stock_OnPrint' }
    | { type: 'Stock_OnInputSearch'; payload: string }
    | { type: 'Stock_OnSearch'; payload: string }
    | { type: 'Stock_OnSort'; payload: string }
    | { type: 'Stock_OnSortDirection'; payload: string }
    | { type: 'Stock_OnOnlyWithWarnings'; payload: boolean }
    | { type: 'Stock_OnPageChange'; payload: number }
    | { type: 'Stock_OnAmountPerPageChange'; payload: number }
    // Reach
    | {
          type: 'Reach_Incoming';
          payload: Result<DealerReach, ApplicationError>;
      }
    | { type: 'Reach_OnToggleInfoBlock' }
    | { type: 'Reach_OnPrint' }
    | {
          type: 'Reach_OnDateRangeChange';
          payload: DateRange;
      }
    // Leads
    | {
          type: 'Leads_Incoming';
          payload: Result<DealerLeads, ApplicationError>;
      }
    | { type: 'Leads_OnToggleInfoBlock' }
    | { type: 'Leads_OnToggleFilterVisibility' }
    | { type: 'Leads_OnDownloadExcel' }
    | { type: 'Leads_OnPrint' }
    | { type: 'Leads_OnSortChange'; payload: DealerLeadsSorting }
    | { type: 'Leads_OnAmountPerPageChange'; payload: number }
    | { type: 'Leads_OnPageChange'; payload: number }
    | { type: 'Leads_OnSearchInput'; payload: string }
    | { type: 'Leads_OnFilterChange'; payload: Set<string> }
    | {
          type: 'Leads_OnPeriodChange';
          payload: DateRange;
      }
    // Prospects
    | {
          type: 'Prospects_Incoming';
          payload: Result<Prospects, ApplicationError>;
      }
    | { type: 'Prospects_OnDownloadExcel' }
    | { type: 'Prospects_OnToggleInfoBlock' }
    | { type: 'Prospects_OnPrint' }
    | { type: 'Prospects_OnPageChange'; payload: number }
    | { type: 'Prospects_OnAmountPerPageChange'; payload: number }
    | {
          type: 'Prospects_OnPeriodChange';
          payload: DateRange;
      }
    // AnalysisOverview
    | {
          type: 'AnalysisOverview_Incoming';
          payload: Result<AnalysisOverview, ApplicationError>;
      }
    | { type: 'AnalysisOverview_OnToggleInfoBlock' }
    | { type: 'AnalysisOverview_OnPrint' }
    | { type: 'AnalysisOverview_OnProfileChange'; payload: string }
    // AnalysisLeadPerformance
    | {
          type: 'AnalysisLeadPerformance_Incoming';
          payload: Result<AnalysisLeadPerformance, ApplicationError>;
      }
    | { type: 'AnalysisLeadPerformance_OnPrint' }
    | { type: 'AnalysisLeadPerformance_OnToggleInfoBlock' }
    | {
          type: 'AnalysisLeadPerformance_OnPeriodChange';
          payload: DateRange;
      }
    // AnalysisMakeModel
    | {
          type: 'AnalysisMakeModel_Incoming';
          payload: Result<AnalysisMakeModel, ApplicationError>;
      }
    | { type: 'AnalysisMakeModel_OnToggleInfoBlock' }
    | { type: 'AnalysisMakeModel_OnPrint' }
    | { type: 'AnalysisMakeModel_OnChangeTab'; payload: AnalysisMakeModelTab }
    // AnalysisCostBenefit
    | {
          type: 'AnalysisCostBenefit_Incoming';
          payload: Result<AnalysisCostBenefit, ApplicationError>;
      }
    | { type: 'AnalysisCostBenefit_OnPrint' }
    // ComparisonAdvicePrice
    | {
          type: 'ComparisonAdvicePrice_Incoming';
          payload: Result<ComparisonAdvicePrice, ApplicationError>;
      }
    | {
          type: 'ComparisonAdvicePrice_OnPrint';
      }
    | {
          type: 'ComparisonAdvicePrice_OnToggleInfoBlock';
      }
    | {
          type: 'ComparisonAdvicePrice_OnProfileChange';
          payload: string;
      }
    | {
          type: 'ComparisonAdvicePrice_OnMakeFilterChange';
          payload: Set<string>;
      }
    | {
          type: 'ComparisonAdvicePrice_OnModelFilterChange';
          payload: Set<string>;
      }
    // ComparisonDaysInStock
    | {
          type: 'ComparisonDaysInStock_Incoming';
          payload: Result<ComparisonDaysInStock, ApplicationError>;
      }
    | {
          type: 'ComparisonDaysInStock_OnPrint';
      }
    | {
          type: 'ComparisonDaysInStock_OnToggleInfoBlock';
      }
    | {
          type: 'ComparisonDaysInStock_OnProfileChange';
          payload: string;
      }
    | {
          type: 'ComparisonDaysInStock_OnMakeFilterChange';
          payload: Set<string>;
      }
    | {
          type: 'ComparisonDaysInStock_OnModelFilterChange';
          payload: Set<string>;
      }
    // ComparisonGeographic
    | {
          type: 'ComparisonGeographic_Incoming';
          payload: Result<ComparisonGeographic, ApplicationError>;
      }
    | { type: 'ComparisonGeographic_OnToggleInfoBlock' }
    | { type: 'ComparisonGeographic_OnPrint' }
    | {
          type: 'ComparisonGeographic_OnTabChange';
          payload: ComparisonGeographicTab;
      }
    | {
          type: 'ComparisonGeographic_OnProfileChange';
          payload: string;
      }
    // SettingsScenarios
    | {
          type: 'SettingsScenarios_Incoming';
          payload: Result<ScenarioSnippet[], ApplicationError>;
      }
    | {
          type: 'SettingsScenarios_OnDelete';
          payload: ScenarioSnippet;
      }
    | {
          type: 'SettingsScenarios_IncomingDelete';
          payload: {
              scenario: ScenarioSnippet;
              wasSuccessful: boolean;
          };
      }
    // SettingsScenario
    | {
          type: 'SettingsScenario_Incoming';
          payload: Result<Scenario, ApplicationError>;
      }
    | {
          type: 'SettingsScenario_IncomingSave';
          payload: Result<Scenario, ApplicationError>;
      }
    | {
          type: 'SettingsScenario_OnSave';
      }
    | {
          type: 'SettingsScenario_OnCancel';
      }
    | {
          type: 'SettingsScenario_OnUpdate';
          payload: Scenario | ScenarioDraft;
      }
    | {
          type: 'SettingsScenario_OnSelectedAvailableDealerChange';
          payload: {
              index: number;
              dealerId: Dealer['id'];
          };
      }
    // SettingsBranches
    | {
          type: 'SettingsBranches_IncomingScenarioList';
          payload: Result<ScenarioSnippet[], ApplicationError>;
      }
    | {
          type: 'SettingsBranches_IncomingScenario';
          payload: Result<Scenario, ApplicationError>;
      }
    | {
          type: 'SettingsBranches_IncomingDealerFilter';
          payload: Result<
              {
                  scenario: Scenario['id'];
                  dealers: Record<Dealer['id'], boolean>;
              },
              ApplicationError
          >;
      }
    | {
          type: 'SettingsBranches_IncomingSave';
          payload: Result<Record<Dealer['id'], boolean>, ApplicationError>;
      }
    | {
          type: 'SettingsBranches_OnUpdate';
          payload: Record<Dealer['id'], boolean>;
      }
    | {
          type: 'SettingsBranches_OnChangeScenario';
          payload: Scenario['id'];
      }
    | {
          type: 'SettingsBranches_OnSave';
      }
    | {
          type: 'SettingsBranches_OnCancel';
      }
    // SettingsCompetingDealers
    | {
          type: 'SettingsCompetingDealers_Incoming';
          payload: Result<CompetitionProfile[], ApplicationError>;
      }
    | {
          type: 'SettingsCompetingDealers_IncomingRemove';
          payload: {
              profile: CompetitionProfile;
              wasSuccessful: boolean;
          };
      }
    | {
          type: 'SettingsCompetingDealers_OnRemove';
          payload: CompetitionProfile;
      }
    // SettingsCompetingDealersProfile
    | {
          type: 'SettingsCompetingDealersProfile_Incoming';
          payload: Result<CompetitionProfile | null, ApplicationError>;
      }
    | {
          type: 'SettingsCompetingDealersProfile_IncomingFilters';
          payload: Result<
              {
                  dealerTypes: { key: string; value: string }[];
                  provinces: { key: string; value: string }[];
                  makes: { key: string; value: string }[];
              },
              ApplicationError
          >;
      }
    | {
          type: 'SettingsCompetingDealersProfile_IncomingDealers';
          payload: {
              filters: DealerProfileFilters;
              result: Result<CompetitionProfileDealer[], ApplicationError>;
          };
      }
    | {
          type: 'SettingsCompetingDealersProfile_IncomingSave';
          payload: Result<CompetitionProfile['id'], ApplicationError>;
      }
    | { type: 'SettingsCompetingDealersProfile_OnChangeName'; payload: string }
    | {
          type: 'SettingsCompetingDealersProfile_OnViewChange';
          payload: SettingsCompetingCompaniesProfileView;
      }
    | {
          type: 'SettingsCompetingDealersProfile_OnChangeSearch';
          payload: string;
      }
    | {
          type: 'SettingsCompetingDealersProfile_OnChangeDealerFilter';
          payload: Set<string>;
      }
    | {
          type: 'SettingsCompetingDealersProfile_OnChangeProvinceFilter';
          payload: Set<string>;
      }
    | {
          type: 'SettingsCompetingDealersProfile_OnChangeDealerMakeFilter';
          payload: Set<string>;
      }
    | {
          type: 'SettingsCompetingDealersProfile_OnChangeStockMakeFilter';
          payload: Set<string>;
      }
    | {
          type: 'SettingsCompetingDealersProfile_OnAddCompetition';
          payload: {
              profileId: CompetitionProfileDraft['id'];
              value: CompetitionProfileDealer;
          };
      }
    | {
          type: 'SettingsCompetingDealersProfile_OnRemoveCompetition';
          payload: {
              profileId: CompetitionProfileDraft['id'];
              value: CompetitionProfileDealer;
          };
      }
    | {
          type: 'SettingsCompetingDealersProfile_OnAddCompetitionAll';
          payload: { profileId: CompetitionProfileDraft['id'] };
      }
    | {
          type: 'SettingsCompetingDealersProfile_OnRemoveCompetitionAll';
          payload: { profileId: CompetitionProfileDraft['id'] };
      }
    | { type: 'SettingsCompetingDealersProfile_OnSave' }
    | { type: 'SettingsCompetingDealersProfile_OnCancel' }
    // TaxationComparison_Incoming
    | {
          type: 'TaxationComparison_Incoming';
          payload: {
              vehicleId: string;
              result: Result<VehicleCompetition, ApplicationError>;
          };
      }
    // Taxation
    | {
          type: 'TaxationDashboard_Incoming';
          payload: {
              vehicleId: string;
              result: Result<VehicleDashboard, ApplicationError>;
          };
      }
    | { type: 'TaxationDashboard_OnToggleInfoBlock' }
    | { type: 'TaxationDashboard_OnPrint' }
    // TaxationComparisonOverview
    | {
          type: 'TaxationComparisonOverview_OnStatusChange';
          payload: {
              vehicleId: string;
              value: VehicleCompetitionStatus;
          };
      }
    | {
          type: 'TaxationComparisonOverview_OnSortChange';
          payload: {
              vehicleId: string;
              value: string;
          };
      }
    | {
          type: 'TaxationComparisonOverview_OnSortDirectionChange';
          payload: {
              vehicleId: string;
              value: SortDirection;
          };
      }
    | { type: 'TaxationComparisonOverview_OnToggleInfoBlock' }
    | { type: 'TaxationComparisonOverview_OnPrint' }
    | { type: 'TaxationComparisonOverview_OnPageChange'; payload: number }
    | {
          type: 'TaxationComparisonOverview_OnAmountPerPageChange';
          payload: number;
      }
    // TaxationComparisonPriceAnalysis
    | {
          type: 'TaxationComparisonPriceAnalysis_OnStatusChange';
          payload: {
              vehicleId: string;
              value: VehicleCompetitionStatus;
          };
      }
    | { type: 'TaxationComparisonPriceAnalysis_OnToggleInfoBlock' }
    | { type: 'TaxationComparisonPriceAnalysis_OnPrint' }
    // TaxationComparisonPriceHistory
    | {
          type: 'TaxationComparisonPriceHistory_Incoming';
          payload: {
              vehicleId: string;
              result: Result<VehicleComparisonPriceHistory, ApplicationError>;
          };
      }
    | {
          type: 'TaxationComparisonPriceHistory_OnStatusChange';
          payload: {
              vehicleId: string;
              value: VehicleCompetitionStatus;
          };
      }
    | { type: 'TaxationComparisonPriceHistory_OnToggleInfoBlock' }
    | { type: 'TaxationComparisonPriceHistory_OnPrint' }
    // TaxationComparisonGeographic
    | {
          type: 'TaxationComparisonGeographic_Incoming';
          payload: {
              vehicleId: string;
              result: Result<VehicleComparisonGeographic, ApplicationError>;
          };
      }
    | {
          type: 'TaxationComparisonGeographic_OnStatusChange';
          payload: {
              vehicleId: string;
              value: VehicleCompetitionStatus;
          };
      }
    | { type: 'TaxationComparisonGeographic_OnToggleInfoBlock' }
    | { type: 'TaxationComparisonGeographic_OnPrint' }
    // TaxationSettings
    | {
          type: 'TaxationSettings_Incoming';
          payload: {
              vehicleId: string;
              result: Result<TaxationSettings, ApplicationError>;
          };
      }
    | {
          type: 'TaxationSettings_IncomingSave';
          payload: {
              vehicleId: string;
              result: Result<TaxationSettings, ApplicationError>;
          };
      }
    | {
          type: 'TaxationSettings_IncomingReset';
          payload: {
              vehicleId: string;
              wasSuccessful: boolean;
          };
      }
    | {
          type: 'TaxationSettings_OnChange';
          payload: {
              vehicleId: string;
              value: TaxationSettings;
          };
      }
    | {
          type: 'TaxationSettings_OnSave';
          payload: {
              vehicleId: string;
          };
      }
    | {
          type: 'TaxationSettings_OnCancel';
          payload: {
              vehicleId: string;
          };
      }
    | {
          type: 'TaxationSettings_OnReset';
          payload: {
              vehicleId: string;
          };
      }
    | { type: 'TaxationSettings_OnToggleInfoBlock' }
    // VehicleDashboard
    | {
          type: 'VehicleDashboard_Incoming';
          payload: {
              vehicleId: string;
              result: Result<VehicleDashboard, ApplicationError>;
          };
      }
    | { type: 'VehicleDashboard_OnToggleInfoBlock' }
    | { type: 'VehicleDashboard_OnPrint' }
    // VehicleReachOverview
    | {
          type: 'VehicleReachOverview_Incoming';
          payload: {
              vehicleId: string;
              result: Result<VehicleReachOverview, ApplicationError>;
          };
      }
    | { type: 'VehicleReachOverview_OnToggleInfoBlock' }
    | { type: 'VehicleReachOverview_OnPrint' }
    // VehicleReachPriceEffect
    | {
          type: 'VehicleReachPriceEffect_Incoming';
          payload: {
              vehicleId: string;
              type: VehicleReachPriceEffectType;
              result: Result<VehicleReachPriceEffect, ApplicationError>;
          };
      }
    | {
          type: 'VehicleReachPriceEffect_OnTabClick';
          payload: {
              vehicleId: string;
              value: VehicleReachPriceEffectType;
          };
      }
    | { type: 'VehicleReachPriceEffect_OnToggleInfoBlock' }
    | { type: 'VehicleReachPriceEffect_OnPrint' }
    // VehicleComparison
    | {
          type: 'VehicleComparison_Incoming';
          payload: {
              vehicleId: string;
              result: Result<VehicleCompetition, ApplicationError>;
          };
      }
    // VehicleComparisonOverview
    | {
          type: 'VehicleComparisonOverview_OnStatusChange';
          payload: {
              vehicleId: string;
              value: VehicleCompetitionStatus;
          };
      }
    | {
          type: 'VehicleComparisonOverview_OnSortChange';
          payload: {
              vehicleId: string;
              value: string;
          };
      }
    | {
          type: 'VehicleComparisonOverview_OnSortDirectionChange';
          payload: {
              vehicleId: string;
              value: SortDirection;
          };
      }
    | { type: 'VehicleComparisonOverview_OnToggleInfoBlock' }
    | { type: 'VehicleComparisonOverview_OnPrint' }
    | { type: 'VehicleComparisonOverview_OnPageChange'; payload: number }
    | {
          type: 'VehicleComparisonOverview_OnAmountPerPageChange';
          payload: number;
      }
    // VehicleComparisonPriceAnalysis
    | {
          type: 'VehicleComparisonPriceAnalysis_OnStatusChange';
          payload: {
              vehicleId: string;
              value: VehicleCompetitionStatus;
          };
      }
    | { type: 'VehicleComparisonPriceAnalysis_OnToggleInfoBlock' }
    | { type: 'VehicleComparisonPriceAnalysis_OnPrint' }
    // VehicleComparisonPriceHistory
    | {
          type: 'VehicleComparisonPriceHistory_Incoming';
          payload: {
              vehicleId: string;
              result: Result<VehicleComparisonPriceHistory, ApplicationError>;
          };
      }
    | {
          type: 'VehicleComparisonPriceHistory_OnStatusChange';
          payload: {
              vehicleId: string;
              value: VehicleCompetitionStatus;
          };
      }
    | { type: 'VehicleComparisonPriceHistory_OnToggleInfoBlock' }
    | { type: 'VehicleComparisonPriceHistory_OnPrint' }
    // VehicleComparisonGeographic
    | {
          type: 'VehicleComparisonGeographic_Incoming';
          payload: {
              vehicleId: string;
              result: Result<VehicleComparisonGeographic, ApplicationError>;
          };
      }
    | {
          type: 'VehicleComparisonGeographic_OnStatusChange';
          payload: {
              vehicleId: string;
              value: VehicleCompetitionStatus;
          };
      }
    | { type: 'VehicleComparisonGeographic_OnToggleInfoBlock' }
    | { type: 'VehicleComparisonGeographic_OnPrint' }
    // VehicleSettings
    | {
          type: 'VehicleSettings_Incoming';
          payload: {
              vehicleId: string;
              result: Result<VehicleSettings, ApplicationError>;
          };
      }
    | {
          type: 'VehicleSettings_IncomingSave';
          payload: {
              vehicleId: string;
              result: Result<VehicleSettings, ApplicationError>;
          };
      }
    | {
          type: 'VehicleSettings_IncomingReset';
          payload: {
              vehicleId: string;
              wasSuccessful: boolean;
          };
      }
    | {
          type: 'VehicleSettings_OnChange';
          payload: {
              vehicleId: string;
              value: VehicleSettings;
          };
      }
    | {
          type: 'VehicleSettings_OnSave';
          payload: {
              vehicleId: string;
          };
      }
    | {
          type: 'VehicleSettings_OnCancel';
          payload: {
              vehicleId: string;
          };
      }
    | {
          type: 'VehicleSettings_OnReset';
          payload: {
              vehicleId: string;
          };
      }
    | { type: 'VehicleSettings_OnToggleInfoBlock' }
    // SettingsStock
    | {
          type: 'SettingsStock_Incoming';
          payload: {
              result: Result<SettingsStock, ApplicationError>;
          };
      }
    | {
          type: 'SettingsStock_IncomingVehicleCount';
          payload: {
              result: Result<number, ApplicationError>;
          };
      }
    | {
          type: 'SettingsStock_IncomingSave';
          payload: {
              result: Result<{ id: string }, ApplicationError>;
          };
      }
    | {
          type: 'SettingsStock_IncomingReset';
          payload: {
              wasSuccessful: boolean;
          };
      }
    | {
          type: 'SettingsStock_OnChange';
          payload: SettingsStockForm;
      }
    | {
          type: 'SettingsStock_OnSave';
      }
    | {
          type: 'SettingsStock_OnReset';
      }
    | {
          type: 'SettingsStock_OnCancel';
      }
    | {
          type: 'VehicleSettings_OnReset';
          payload: {
              vehicleId: string;
          };
      }
    | {
          type: 'SettingsStock_OnSearchVehicle';
          payload: string;
      }
    | {
          type: 'SettingsStock_IncomingSearchVehicle';
          payload: Result<Vehicle[], ApplicationError>;
      }
    // SettingsNotifications
    | {
          type: 'SettingsNotifications_Incoming';
          payload: {
              result: Result<NotificationSettings, ApplicationError>;
          };
      }
    | {
          type: 'SettingsNotifications_IncomingSave';
          payload: {
              result: Result<{ id: string }, ApplicationError>;
          };
      }
    | {
          type: 'SettingsNotifications_IncomingReset';
          payload: { wasSuccessful: boolean };
      }
    | {
          type: 'SettingsNotifications_IncomingSendTestMail';
          payload: Result.Result<{ error: boolean }, ApplicationError>;
      }
    | { type: 'SettingsNotifications_OnSendTestMail' }
    | {
          type: 'SettingsNotifications_OnChange';
          payload: NotificationSettings;
      }
    | {
          type: 'SettingsNotifications_OnSave';
      }
    | {
          type: 'SettingsNotifications_OnReset';
      }
    | {
          type: 'SettingsNotifications_OnCancel';
      }
    // SettingsCompetingVehicles
    | {
          type: 'SettingsCompetingVehicles_Incoming';
          payload: {
              result: Result<VehicleSettings, ApplicationError>;
          };
      }
    | {
          type: 'SettingsCompetingVehicles_IncomingSave';
          payload: {
              result: Result<{ id: string }, ApplicationError>;
          };
      }
    | {
          type: 'SettingsCompetingVehicles_IncomingReset';
          payload: { wasSuccessful: boolean };
      }
    | {
          type: 'SettingsCompetingVehicles_OnChange';
          payload: { value: VehicleSettings };
      }
    | {
          type: 'SettingsCompetingVehicles_OnSave';
      }
    | {
          type: 'SettingsCompetingVehicles_OnReset';
      }
    | {
          type: 'SettingsCompetingVehicles_OnCancel';
      };

export const Msg: OfUnion<Msg> = {
    DeviceLayout: (payload) => ({ type: 'DeviceLayout', payload }),
    OnRoute: (payload) => ({ type: 'OnRoute', payload }),
    NavigateBack: () => ({ type: 'NavigateBack' }),
    Navigate: (payload) => ({ type: 'Navigate', payload }),
    ToggleNavigation: () => ({ type: 'ToggleNavigation' }),
    Application_LoginWithToken: (payload) => ({
        type: 'Application_LoginWithToken',
        payload,
    }),
    Application_IncomingLogout: (payload) => ({
        type: 'Application_IncomingLogout',
        payload,
    }),
    Incoming_Token: (payload) => ({ type: 'Incoming_Token', payload }),
    Incoming_Session: (payload) => ({ type: 'Incoming_Session', payload }),
    Incoming_Vehicle: (payload) => ({ type: 'Incoming_Vehicle', payload }),
    Incoming_CompetingDealerProfiles: (payload) => ({
        type: 'Incoming_CompetingDealerProfiles',
        payload,
    }),
    Incoming_DaysInStockGoal: (payload) => ({
        type: 'Incoming_DaysInStockGoal',
        payload,
    }),
    Incoming_Dealers: (payload) => ({ type: 'Incoming_Dealers', payload }),
    Incoming_DealerFilter: (payload) => ({
        type: 'Incoming_DealerFilter',
        payload,
    }),
    Incoming_StockSelection: (payload) => ({
        type: 'Incoming_StockSelection',
        payload,
    }),
    TaxationWidget_OnInputLicensePlate: (payload) => ({
        type: 'TaxationWidget_OnInputLicensePlate',
        payload,
    }),
    TaxationWidget_OnInputMileage: (payload) => ({
        type: 'TaxationWidget_OnInputMileage',
        payload,
    }),
    TaxationWidget_OnSubmit: () => ({
        type: 'TaxationWidget_OnSubmit',
    }),
    TaxationWidget_OnClearErrorMessage: () => ({
        type: 'TaxationWidget_OnClearErrorMessage',
    }),
    TaxationWidget_IncomingSubmit: (payload) => ({
        type: 'TaxationWidget_IncomingSubmit',
        payload,
    }),
    Login_OnInputUsername: (payload) => ({
        type: 'Login_OnInputUsername',
        payload,
    }),
    Login_OnInputPassword: (payload) => ({
        type: 'Login_OnInputPassword',
        payload,
    }),
    Login_OnInputCaptcha: (payload) => ({
        type: 'Login_OnInputCaptcha',
        payload,
    }),
    Login_OnSubmit: () => ({ type: 'Login_OnSubmit' }),
    Login_IncomingAuthentication: (payload) => ({
        type: 'Login_IncomingAuthentication',
        payload,
    }),
    Dashboard_IncomingDealerDashboard: (payload) => ({
        type: 'Dashboard_IncomingDealerDashboard',
        payload,
    }),
    Dashboard_OnSortStockSummary: (payload) => ({
        type: 'Dashboard_OnSortStockSummary',
        payload,
    }),
    Dashboard_OnToggleInfoBlock: () => ({
        type: 'Dashboard_OnToggleInfoBlock',
    }),
    Dashboard_OnPrint: () => ({ type: 'Dashboard_OnPrint' }),
    Dashboard_OnSelectDealer: (payload) => ({
        type: 'Dashboard_OnSelectDealer',
        payload,
    }),
    Dashboard_OnSelectAllDealers: () => ({
        type: 'Dashboard_OnSelectAllDealers',
    }),
    Stock_OnDownloadExcel: () => ({ type: 'Stock_OnDownloadExcel' }),
    Stock_OnToggleInfoBlock: () => ({ type: 'Stock_OnToggleInfoBlock' }),
    Stock_OnPrint: () => ({ type: 'Stock_OnPrint' }),
    Stock_OnInputSearch: (payload) => ({
        type: 'Stock_OnInputSearch',
        payload,
    }),
    Stock_OnSearch: (payload) => ({ type: 'Stock_OnSearch', payload }),
    Stock_OnSort: (payload) => ({ type: 'Stock_OnSort', payload }),
    Stock_OnSortDirection: (payload) => ({
        type: 'Stock_OnSortDirection',
        payload,
    }),
    Stock_OnOnlyWithWarnings: (payload) => ({
        type: 'Stock_OnOnlyWithWarnings',
        payload,
    }),
    Stock_OnPageChange: (payload) => ({ type: 'Stock_OnPageChange', payload }),
    Stock_OnAmountPerPageChange: (payload) => ({
        type: 'Stock_OnAmountPerPageChange',
        payload,
    }),
    Stock_Incoming: (payload) => ({
        type: 'Stock_Incoming',
        payload,
    }),
    Reach_Incoming: (payload) => ({ type: 'Reach_Incoming', payload }),
    Reach_OnToggleInfoBlock: () => ({ type: 'Reach_OnToggleInfoBlock' }),
    Reach_OnPrint: () => ({ type: 'Reach_OnPrint' }),
    Reach_OnDateRangeChange: (payload) => ({
        type: 'Reach_OnDateRangeChange',
        payload,
    }),
    Leads_Incoming: (payload) => ({ type: 'Leads_Incoming', payload }),
    Leads_OnToggleInfoBlock: () => ({ type: 'Leads_OnToggleInfoBlock' }),
    Leads_OnToggleFilterVisibility: () => ({
        type: 'Leads_OnToggleFilterVisibility',
    }),
    Leads_OnDownloadExcel: () => ({ type: 'Leads_OnDownloadExcel' }),
    Leads_OnPrint: () => ({ type: 'Leads_OnPrint' }),
    Leads_OnSortChange: (payload) => ({ type: 'Leads_OnSortChange', payload }),
    Leads_OnAmountPerPageChange: (payload) => ({
        type: 'Leads_OnAmountPerPageChange',
        payload,
    }),
    Leads_OnPageChange: (payload) => ({ type: 'Leads_OnPageChange', payload }),
    Leads_OnSearchInput: (payload) => ({
        type: 'Leads_OnSearchInput',
        payload,
    }),
    Leads_OnFilterChange: (payload) => ({
        type: 'Leads_OnFilterChange',
        payload,
    }),
    Leads_OnPeriodChange: (payload) => ({
        type: 'Leads_OnPeriodChange',
        payload,
    }),
    Prospects_Incoming: (payload) => ({ type: 'Prospects_Incoming', payload }),
    Prospects_OnDownloadExcel: () => ({ type: 'Prospects_OnDownloadExcel' }),
    Prospects_OnToggleInfoBlock: () => ({
        type: 'Prospects_OnToggleInfoBlock',
    }),
    Prospects_OnPrint: () => ({ type: 'Prospects_OnPrint' }),
    Prospects_OnPageChange: (payload) => ({
        type: 'Prospects_OnPageChange',
        payload,
    }),
    Prospects_OnAmountPerPageChange: (payload) => ({
        type: 'Prospects_OnAmountPerPageChange',
        payload,
    }),
    Prospects_OnPeriodChange: (payload) => ({
        type: 'Prospects_OnPeriodChange',
        payload,
    }),
    AnalysisOverview_Incoming: (payload) => ({
        type: 'AnalysisOverview_Incoming',
        payload,
    }),
    AnalysisOverview_OnToggleInfoBlock: () => ({
        type: 'AnalysisOverview_OnToggleInfoBlock',
    }),
    AnalysisOverview_OnPrint: () => ({ type: 'AnalysisOverview_OnPrint' }),
    AnalysisOverview_OnProfileChange: (payload) => ({
        type: 'AnalysisOverview_OnProfileChange',
        payload,
    }),
    AnalysisLeadPerformance_Incoming: (payload) => ({
        type: 'AnalysisLeadPerformance_Incoming',
        payload,
    }),
    AnalysisLeadPerformance_OnPrint: () => ({
        type: 'AnalysisLeadPerformance_OnPrint',
    }),
    AnalysisLeadPerformance_OnToggleInfoBlock: () => ({
        type: 'AnalysisLeadPerformance_OnToggleInfoBlock',
    }),
    AnalysisLeadPerformance_OnPeriodChange: (payload) => ({
        type: 'AnalysisLeadPerformance_OnPeriodChange',
        payload,
    }),
    AnalysisMakeModel_Incoming: (payload) => ({
        type: 'AnalysisMakeModel_Incoming',
        payload,
    }),
    AnalysisMakeModel_OnToggleInfoBlock: () => ({
        type: 'AnalysisMakeModel_OnToggleInfoBlock',
    }),
    AnalysisMakeModel_OnPrint: () => ({ type: 'AnalysisMakeModel_OnPrint' }),
    AnalysisMakeModel_OnChangeTab: (payload) => ({
        type: 'AnalysisMakeModel_OnChangeTab',
        payload,
    }),
    AnalysisCostBenefit_Incoming: (payload) => ({
        type: 'AnalysisCostBenefit_Incoming',
        payload,
    }),
    AnalysisCostBenefit_OnPrint: () => ({
        type: 'AnalysisCostBenefit_OnPrint',
    }),
    ComparisonAdvicePrice_Incoming: (payload) => ({
        type: 'ComparisonAdvicePrice_Incoming',
        payload,
    }),
    ComparisonAdvicePrice_OnPrint: () => ({
        type: 'ComparisonAdvicePrice_OnPrint',
    }),
    ComparisonAdvicePrice_OnToggleInfoBlock: () => ({
        type: 'ComparisonAdvicePrice_OnToggleInfoBlock',
    }),
    ComparisonAdvicePrice_OnProfileChange: (payload) => ({
        type: 'ComparisonAdvicePrice_OnProfileChange',
        payload,
    }),
    ComparisonAdvicePrice_OnMakeFilterChange: (payload) => ({
        type: 'ComparisonAdvicePrice_OnMakeFilterChange',
        payload,
    }),
    ComparisonAdvicePrice_OnModelFilterChange: (payload) => ({
        type: 'ComparisonAdvicePrice_OnModelFilterChange',
        payload,
    }),
    ComparisonDaysInStock_Incoming: (payload) => ({
        type: 'ComparisonDaysInStock_Incoming',
        payload,
    }),
    ComparisonDaysInStock_OnPrint: () => ({
        type: 'ComparisonDaysInStock_OnPrint',
    }),
    ComparisonDaysInStock_OnToggleInfoBlock: () => ({
        type: 'ComparisonDaysInStock_OnToggleInfoBlock',
    }),
    ComparisonDaysInStock_OnProfileChange: (payload) => ({
        type: 'ComparisonDaysInStock_OnProfileChange',
        payload,
    }),
    ComparisonDaysInStock_OnMakeFilterChange: (payload) => ({
        type: 'ComparisonDaysInStock_OnMakeFilterChange',
        payload,
    }),
    ComparisonDaysInStock_OnModelFilterChange: (payload) => ({
        type: 'ComparisonDaysInStock_OnModelFilterChange',
        payload,
    }),
    ComparisonGeographic_Incoming: (payload) => ({
        type: 'ComparisonGeographic_Incoming',
        payload,
    }),
    ComparisonGeographic_OnToggleInfoBlock: () => ({
        type: 'ComparisonGeographic_OnToggleInfoBlock',
    }),
    ComparisonGeographic_OnPrint: () => ({
        type: 'ComparisonGeographic_OnPrint',
    }),
    ComparisonGeographic_OnTabChange: (payload) => ({
        type: 'ComparisonGeographic_OnTabChange',
        payload,
    }),
    ComparisonGeographic_OnProfileChange: (payload) => ({
        type: 'ComparisonGeographic_OnProfileChange',
        payload,
    }),
    SettingsScenarios_Incoming: (payload) => ({
        type: 'SettingsScenarios_Incoming',
        payload,
    }),
    SettingsScenarios_OnDelete: (payload) => ({
        type: 'SettingsScenarios_OnDelete',
        payload,
    }),
    SettingsScenarios_IncomingDelete: (payload) => ({
        type: 'SettingsScenarios_IncomingDelete',
        payload,
    }),
    SettingsScenario_Incoming: (payload) => ({
        type: 'SettingsScenario_Incoming',
        payload,
    }),
    SettingsScenario_IncomingSave: (payload) => ({
        type: 'SettingsScenario_IncomingSave',
        payload,
    }),
    SettingsScenario_OnUpdate: (payload) => ({
        type: 'SettingsScenario_OnUpdate',
        payload,
    }),
    SettingsScenario_OnSave: () => ({
        type: 'SettingsScenario_OnSave',
    }),
    SettingsScenario_OnCancel: () => ({
        type: 'SettingsScenario_OnCancel',
    }),
    SettingsScenario_OnSelectedAvailableDealerChange: (payload) => ({
        type: 'SettingsScenario_OnSelectedAvailableDealerChange',
        payload,
    }),
    SettingsBranches_IncomingScenarioList: (payload) => ({
        type: 'SettingsBranches_IncomingScenarioList',
        payload,
    }),
    SettingsBranches_IncomingScenario: (payload) => ({
        type: 'SettingsBranches_IncomingScenario',
        payload,
    }),
    SettingsBranches_IncomingDealerFilter: (payload) => ({
        type: 'SettingsBranches_IncomingDealerFilter',
        payload,
    }),
    SettingsBranches_IncomingSave: (payload) => ({
        type: 'SettingsBranches_IncomingSave',
        payload,
    }),
    SettingsBranches_OnChangeScenario: (payload) => ({
        type: 'SettingsBranches_OnChangeScenario',
        payload,
    }),
    SettingsBranches_OnUpdate: (payload) => ({
        type: 'SettingsBranches_OnUpdate',
        payload,
    }),
    SettingsBranches_OnSave: () => ({
        type: 'SettingsBranches_OnSave',
    }),
    SettingsBranches_OnCancel: () => ({
        type: 'SettingsBranches_OnCancel',
    }),
    SettingsCompetingDealers_Incoming: (payload) => ({
        type: 'SettingsCompetingDealers_Incoming',
        payload,
    }),
    SettingsCompetingDealers_IncomingRemove: (payload) => ({
        type: 'SettingsCompetingDealers_IncomingRemove',
        payload,
    }),
    SettingsCompetingDealers_OnRemove: (payload) => ({
        type: 'SettingsCompetingDealers_OnRemove',
        payload,
    }),
    SettingsCompetingDealersProfile_Incoming: (payload) => ({
        type: 'SettingsCompetingDealersProfile_Incoming',
        payload,
    }),
    SettingsCompetingDealersProfile_IncomingFilters: (payload) => ({
        type: 'SettingsCompetingDealersProfile_IncomingFilters',
        payload,
    }),
    SettingsCompetingDealersProfile_IncomingSave: (payload) => ({
        type: 'SettingsCompetingDealersProfile_IncomingSave',
        payload,
    }),
    SettingsCompetingDealersProfile_IncomingDealers: (payload) => ({
        type: 'SettingsCompetingDealersProfile_IncomingDealers',
        payload,
    }),
    SettingsCompetingDealersProfile_OnViewChange: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnViewChange',
        payload,
    }),
    SettingsCompetingDealersProfile_OnChangeName: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnChangeName',
        payload,
    }),
    SettingsCompetingDealersProfile_OnChangeSearch: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnChangeSearch',
        payload,
    }),
    SettingsCompetingDealersProfile_OnChangeDealerFilter: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnChangeDealerFilter',
        payload,
    }),
    SettingsCompetingDealersProfile_OnChangeProvinceFilter: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnChangeProvinceFilter',
        payload,
    }),
    SettingsCompetingDealersProfile_OnChangeDealerMakeFilter: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnChangeDealerMakeFilter',
        payload,
    }),
    SettingsCompetingDealersProfile_OnChangeStockMakeFilter: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnChangeStockMakeFilter',
        payload,
    }),
    SettingsCompetingDealersProfile_OnAddCompetition: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnAddCompetition',
        payload,
    }),
    SettingsCompetingDealersProfile_OnRemoveCompetition: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnRemoveCompetition',
        payload,
    }),
    SettingsCompetingDealersProfile_OnAddCompetitionAll: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnAddCompetitionAll',
        payload,
    }),
    SettingsCompetingDealersProfile_OnRemoveCompetitionAll: (payload) => ({
        type: 'SettingsCompetingDealersProfile_OnRemoveCompetitionAll',
        payload,
    }),
    SettingsCompetingDealersProfile_OnSave: () => ({
        type: 'SettingsCompetingDealersProfile_OnSave',
    }),
    SettingsCompetingDealersProfile_OnCancel: () => ({
        type: 'SettingsCompetingDealersProfile_OnCancel',
    }),
    TaxationDashboard_Incoming: (payload) => ({
        type: 'TaxationDashboard_Incoming',
        payload,
    }),
    TaxationDashboard_OnToggleInfoBlock: () => ({
        type: 'TaxationDashboard_OnToggleInfoBlock',
    }),
    TaxationDashboard_OnPrint: () => ({
        type: 'TaxationDashboard_OnPrint',
    }),
    TaxationComparison_Incoming: (payload) => ({
        type: 'TaxationComparison_Incoming',
        payload,
    }),
    TaxationComparisonGeographic_Incoming: (payload) => ({
        type: 'TaxationComparisonGeographic_Incoming',
        payload,
    }),
    TaxationComparisonGeographic_OnPrint: () => ({
        type: 'TaxationComparisonGeographic_OnPrint',
    }),
    TaxationComparisonGeographic_OnStatusChange: (payload) => ({
        type: 'TaxationComparisonGeographic_OnStatusChange',
        payload,
    }),
    TaxationComparisonGeographic_OnToggleInfoBlock: () => ({
        type: 'TaxationComparisonGeographic_OnToggleInfoBlock',
    }),
    TaxationComparisonOverview_OnAmountPerPageChange: (payload) => ({
        type: 'TaxationComparisonOverview_OnAmountPerPageChange',
        payload,
    }),
    TaxationComparisonOverview_OnPageChange: (payload) => ({
        type: 'TaxationComparisonOverview_OnPageChange',
        payload,
    }),
    TaxationComparisonOverview_OnPrint: () => ({
        type: 'TaxationComparisonOverview_OnPrint',
    }),
    TaxationComparisonOverview_OnSortChange: (payload) => ({
        type: 'TaxationComparisonOverview_OnSortChange',
        payload,
    }),
    TaxationComparisonOverview_OnSortDirectionChange: (payload) => ({
        type: 'TaxationComparisonOverview_OnSortDirectionChange',
        payload,
    }),
    TaxationComparisonOverview_OnStatusChange: (payload) => ({
        type: 'TaxationComparisonOverview_OnStatusChange',
        payload,
    }),
    TaxationComparisonOverview_OnToggleInfoBlock: () => ({
        type: 'TaxationComparisonOverview_OnToggleInfoBlock',
    }),
    TaxationComparisonPriceAnalysis_OnPrint: () => ({
        type: 'TaxationComparisonPriceAnalysis_OnPrint',
    }),
    TaxationComparisonPriceAnalysis_OnStatusChange: (payload) => ({
        type: 'TaxationComparisonPriceAnalysis_OnStatusChange',
        payload,
    }),
    TaxationComparisonPriceAnalysis_OnToggleInfoBlock: () => ({
        type: 'TaxationComparisonPriceAnalysis_OnToggleInfoBlock',
    }),
    TaxationComparisonPriceHistory_Incoming: (payload) => ({
        type: 'TaxationComparisonPriceHistory_Incoming',
        payload,
    }),
    TaxationComparisonPriceHistory_OnPrint: () => ({
        type: 'TaxationComparisonPriceHistory_OnPrint',
    }),
    TaxationComparisonPriceHistory_OnStatusChange: (payload) => ({
        type: 'TaxationComparisonPriceHistory_OnStatusChange',
        payload,
    }),
    TaxationComparisonPriceHistory_OnToggleInfoBlock: () => ({
        type: 'TaxationComparisonPriceHistory_OnToggleInfoBlock',
    }),
    TaxationSettings_Incoming: (payload) => ({
        type: 'TaxationSettings_Incoming',
        payload,
    }),
    TaxationSettings_IncomingReset: (payload) => ({
        type: 'TaxationSettings_IncomingReset',
        payload,
    }),
    TaxationSettings_IncomingSave: (payload) => ({
        type: 'TaxationSettings_IncomingSave',
        payload,
    }),
    TaxationSettings_OnCancel: (payload) => ({
        type: 'TaxationSettings_OnCancel',
        payload,
    }),
    TaxationSettings_OnChange: (payload) => ({
        type: 'TaxationSettings_OnChange',
        payload,
    }),
    TaxationSettings_OnReset: (payload) => ({
        type: 'TaxationSettings_OnReset',
        payload,
    }),
    TaxationSettings_OnSave: (payload) => ({
        type: 'TaxationSettings_OnSave',
        payload,
    }),
    TaxationSettings_OnToggleInfoBlock: () => ({
        type: 'TaxationSettings_OnToggleInfoBlock',
    }),
    VehicleDashboard_Incoming: (payload) => ({
        type: 'VehicleDashboard_Incoming',
        payload,
    }),
    VehicleDashboard_OnToggleInfoBlock: () => ({
        type: 'VehicleDashboard_OnToggleInfoBlock',
    }),
    VehicleDashboard_OnPrint: () => ({
        type: 'VehicleDashboard_OnPrint',
    }),
    VehicleReachOverview_Incoming: (payload) => ({
        type: 'VehicleReachOverview_Incoming',
        payload,
    }),
    VehicleReachOverview_OnToggleInfoBlock: () => ({
        type: 'VehicleReachOverview_OnToggleInfoBlock',
    }),
    VehicleReachOverview_OnPrint: () => ({
        type: 'VehicleReachOverview_OnPrint',
    }),
    VehicleReachPriceEffect_Incoming: (payload) => ({
        type: 'VehicleReachPriceEffect_Incoming',
        payload,
    }),
    VehicleReachPriceEffect_OnTabClick: (payload) => ({
        type: 'VehicleReachPriceEffect_OnTabClick',
        payload,
    }),
    VehicleReachPriceEffect_OnToggleInfoBlock: () => ({
        type: 'VehicleReachPriceEffect_OnToggleInfoBlock',
    }),
    VehicleReachPriceEffect_OnPrint: () => ({
        type: 'VehicleReachPriceEffect_OnPrint',
    }),
    VehicleComparison_Incoming: (payload) => ({
        type: 'VehicleComparison_Incoming',
        payload,
    }),
    VehicleComparisonOverview_OnStatusChange: (payload) => ({
        type: 'VehicleComparisonOverview_OnStatusChange',
        payload,
    }),
    VehicleComparisonOverview_OnPrint: () => ({
        type: 'VehicleComparisonOverview_OnPrint',
    }),
    VehicleComparisonOverview_OnSortChange: (payload) => ({
        type: 'VehicleComparisonOverview_OnSortChange',
        payload,
    }),
    VehicleComparisonOverview_OnSortDirectionChange: (payload) => ({
        type: 'VehicleComparisonOverview_OnSortDirectionChange',
        payload,
    }),
    VehicleComparisonOverview_OnToggleInfoBlock: () => ({
        type: 'VehicleComparisonOverview_OnToggleInfoBlock',
    }),
    VehicleComparisonOverview_OnPageChange: (payload) => ({
        type: 'VehicleComparisonOverview_OnPageChange',
        payload,
    }),
    VehicleComparisonOverview_OnAmountPerPageChange: (payload) => ({
        type: 'VehicleComparisonOverview_OnAmountPerPageChange',
        payload,
    }),
    VehicleComparisonPriceAnalysis_OnStatusChange: (payload) => ({
        type: 'VehicleComparisonPriceAnalysis_OnStatusChange',
        payload,
    }),
    VehicleComparisonPriceAnalysis_OnToggleInfoBlock: () => ({
        type: 'VehicleComparisonPriceAnalysis_OnToggleInfoBlock',
    }),
    VehicleComparisonPriceAnalysis_OnPrint: () => ({
        type: 'VehicleComparisonPriceAnalysis_OnPrint',
    }),
    VehicleComparisonPriceHistory_Incoming: (payload) => ({
        type: 'VehicleComparisonPriceHistory_Incoming',
        payload,
    }),
    VehicleComparisonPriceHistory_OnStatusChange: (payload) => ({
        type: 'VehicleComparisonPriceHistory_OnStatusChange',
        payload,
    }),
    VehicleComparisonPriceHistory_OnToggleInfoBlock: () => ({
        type: 'VehicleComparisonPriceHistory_OnToggleInfoBlock',
    }),
    VehicleComparisonPriceHistory_OnPrint: () => ({
        type: 'VehicleComparisonPriceHistory_OnPrint',
    }),
    VehicleComparisonGeographic_Incoming: (payload) => ({
        type: 'VehicleComparisonGeographic_Incoming',
        payload,
    }),
    VehicleComparisonGeographic_OnStatusChange: (payload) => ({
        type: 'VehicleComparisonGeographic_OnStatusChange',
        payload,
    }),
    VehicleComparisonGeographic_OnToggleInfoBlock: () => ({
        type: 'VehicleComparisonGeographic_OnToggleInfoBlock',
    }),
    VehicleComparisonGeographic_OnPrint: () => ({
        type: 'VehicleComparisonGeographic_OnPrint',
    }),
    VehicleSettings_Incoming: (payload) => ({
        type: 'VehicleSettings_Incoming',
        payload,
    }),
    VehicleSettings_IncomingSave: (payload) => ({
        type: 'VehicleSettings_IncomingSave',
        payload,
    }),
    VehicleSettings_IncomingReset: (payload) => ({
        type: 'VehicleSettings_IncomingReset',
        payload,
    }),
    VehicleSettings_OnChange: (payload) => ({
        type: 'VehicleSettings_OnChange',
        payload,
    }),
    VehicleSettings_OnSave: (payload) => ({
        type: 'VehicleSettings_OnSave',
        payload,
    }),
    VehicleSettings_OnCancel: (payload) => ({
        type: 'VehicleSettings_OnCancel',
        payload,
    }),
    VehicleSettings_OnReset: (payload) => ({
        type: 'VehicleSettings_OnReset',
        payload,
    }),
    VehicleSettings_OnToggleInfoBlock: () => ({
        type: 'VehicleSettings_OnToggleInfoBlock',
    }),
    SettingsStock_Incoming: (payload) => ({
        type: 'SettingsStock_Incoming',
        payload,
    }),
    SettingsStock_IncomingVehicleCount: (payload) => ({
        type: 'SettingsStock_IncomingVehicleCount',
        payload,
    }),
    SettingsStock_IncomingSave: (payload) => ({
        type: 'SettingsStock_IncomingSave',
        payload,
    }),
    SettingsStock_IncomingReset: (payload) => ({
        type: 'SettingsStock_IncomingReset',
        payload,
    }),
    SettingsStock_OnChange: (payload) => ({
        type: 'SettingsStock_OnChange',
        payload,
    }),
    SettingsStock_OnCancel: () => ({
        type: 'SettingsStock_OnCancel',
    }),
    SettingsStock_OnReset: () => ({
        type: 'SettingsStock_OnReset',
    }),
    SettingsStock_OnSave: () => ({
        type: 'SettingsStock_OnSave',
    }),
    SettingsStock_OnSearchVehicle: (payload) => ({
        type: 'SettingsStock_OnSearchVehicle',
        payload,
    }),
    SettingsStock_IncomingSearchVehicle: (payload) => ({
        type: 'SettingsStock_IncomingSearchVehicle',
        payload,
    }),
    SettingsNotifications_Incoming: (payload) => ({
        type: 'SettingsNotifications_Incoming',
        payload,
    }),
    SettingsNotifications_IncomingReset: (payload) => ({
        type: 'SettingsNotifications_IncomingReset',
        payload,
    }),
    SettingsNotifications_IncomingSave: (payload) => ({
        type: 'SettingsNotifications_IncomingSave',
        payload,
    }),
    SettingsNotifications_IncomingSendTestMail: (payload) => ({
        type: 'SettingsNotifications_IncomingSendTestMail',
        payload,
    }),
    SettingsNotifications_OnSendTestMail: () => ({
        type: 'SettingsNotifications_OnSendTestMail',
    }),
    SettingsNotifications_OnChange: (payload) => ({
        type: 'SettingsNotifications_OnChange',
        payload,
    }),
    SettingsNotifications_OnSave: () => ({
        type: 'SettingsNotifications_OnSave',
    }),
    SettingsNotifications_OnReset: () => ({
        type: 'SettingsNotifications_OnReset',
    }),
    SettingsNotifications_OnCancel: () => ({
        type: 'SettingsNotifications_OnCancel',
    }),
    SettingsCompetingVehicles_Incoming: (payload) => ({
        type: 'SettingsCompetingVehicles_Incoming',
        payload,
    }),
    SettingsCompetingVehicles_IncomingSave: (payload) => ({
        type: 'SettingsCompetingVehicles_IncomingSave',
        payload,
    }),
    SettingsCompetingVehicles_IncomingReset: (payload) => ({
        type: 'SettingsCompetingVehicles_IncomingReset',
        payload,
    }),
    SettingsCompetingVehicles_OnChange: (payload) => ({
        type: 'SettingsCompetingVehicles_OnChange',
        payload,
    }),
    SettingsCompetingVehicles_OnSave: () => ({
        type: 'SettingsCompetingVehicles_OnSave',
    }),
    SettingsCompetingVehicles_OnCancel: () => ({
        type: 'SettingsCompetingVehicles_OnCancel',
    }),
    SettingsCompetingVehicles_OnReset: () => ({
        type: 'SettingsCompetingVehicles_OnReset',
    }),
};

type OfUnion<T extends { type: string }> = {
    [P in T['type']]: T extends { type: P; payload: infer Payload }
        ? (payload: Payload) => Extract<T, { type: P; payload: Payload }>
        : () => Extract<T, { type: P }>;
};
