import { Html } from '../../Application';
import { Account, DeviceLayout, Vehicle } from '../../Model';
import { html, nothing } from 'lit';
import '../Layouts/AtaLayoutShell';
import '../Organisms/AtaNavigation';
import '../Organisms/AtaNavigationGroup';
import '../Organisms/AtaPageHeader';
import '../Organisms/AtaVehiclePageHeader';
import '../Layouts/AtaHorizontalLayout';
import '../Layouts/AtaVerticalLayout';
import '../Atoms/AtaBigHeading';
import '../Atoms/AtaMediumHeading';
import '../Atoms/AtaVehicleImage';
import mainNavigation from './MainNavigation';
import vehicleNavigation from './VehicleNavigation';
import Format from '../Atoms/Format';
import { intlText } from '../Atoms/IntlText';
import * as VehicleUtil from '../../Vehicle';
import Icon from '../Atoms/Icon';
import pageNavigation from './PageNavigation';
import { padding, paddingBlockInline, paddingEach } from './Padding';
import appBar from './AppBar';
import { button } from './Button';
import taxationWidget from './TaxationWidget';
import appFooter from './AppFooter';
import { Route } from '../../Route';
import { version } from '../../Environment';

export default function vehicleShell(config: {
    deviceLayout: DeviceLayout;
    pageTitle: string;
    pageActions: Html[];
    pageNavigations?: {
        previous?: { href: string; label: string };
        next?: { href: string; label: string };
    };
    content: Html;
    account: Account;
    vehicle: Vehicle;
    activeRoute: Route;
    isNavigationVisible: boolean;
    onNavigate: (href: string) => void;
    onToggleNavigation: () => void;
    taxationWidget?: {
        licensePlate: string;
        mileage: string;
        errorMessage: string | null;
        onLicensePlateChange: (value: string) => void;
        onMileageChange: (value: string) => void;
        onSubmit: () => void;
        onClearErrorMessage: () => void;
    };
}): Html {
    return html`
        <ata-layout-shell ?navigation-visible="${config.isNavigationVisible}">
            <div slot="header">
                ${viewAppBar(config.deviceLayout, config.onToggleNavigation)}
            </div>

            <div
                slot="navigation"
                style="block-size: 100%; background: var(--ata--app--navigation--backgroundColor); display: flex; flex-direction: column; overflow: hidden;"
            >
                <ata-navigation-group style="flex: 1; overflow: hidden;">
                    ${mainNavigation(
                        config.account,
                        config.activeRoute,
                        false,
                        config.onNavigate,
                    )}
                    ${vehicleNavigation(
                        true,
                        config.activeRoute,
                        config.vehicle.id.value,
                        config.onNavigate,
                    )}
                </ata-navigation-group>

                ${config.taxationWidget !== undefined
                    ? html`
                          <div
                              style="flex: 0; position: sticky; inset: auto 0 0 0; padding: var(--dimensions--spacing--medium); box-sizing: border-box;"
                          >
                              ${taxationWidget(config.taxationWidget)}
                          </div>
                      `
                    : nothing}
            </div>

            ${vehiclePageHeader({
                title: html`${config.pageTitle}`,
                pageActions: config.pageActions,
                vehicle: config.vehicle,
            })}

            <main style="position: relative"> ${config.content} </main>

            ${config.pageNavigations
                ? paddingEach(
                      {
                          blockStart: 'large',
                          blockEnd: 'none',
                          inlineStart: 'none',
                          inlineEnd: 'none',
                      },
                      html`<hr />`,
                  )
                : nothing}
            ${padding(
                'large',
                pageNavigation(
                    {
                        previous: config.pageNavigations?.previous,
                        next: config.pageNavigations?.next,
                    },
                    config.onNavigate,
                ),
            )}
            ${appFooter(config.account.lastUpdate)}
        </ata-layout-shell>
    `;
}

function viewAppBar(
    deviceLayout: DeviceLayout,
    onToggleNavigation: () => void,
): Html {
    switch (deviceLayout) {
        case 'Desktop': {
            return appBar({
                start: paddingBlockInline(
                    { block: 'none', inline: 'medium' },
                    html`<img
                        loading="lazy"
                        src="https://analytics.autotrack.nl/assets/images/autotrack-analytics.png"
                        style="block-size: 3rem"
                        title="AutoTrack Analytics v${version}"
                    />`,
                ),
            });
        }

        case 'Mobile': {
            return appBar({
                center: paddingBlockInline(
                    { block: 'none', inline: 'medium' },
                    html`<img
                        loading="lazy"
                        src="https://analytics.autotrack.nl/assets/images/autotrack-analytics.png"
                        style="block-size: 3rem"
                        title="AutoTrack Analytics v${version}"
                    />`,
                ),
                end: button({
                    theme: 'Secondary',
                    content: Icon.hamburger(),
                    onClick: onToggleNavigation,
                }),
            });
        }
    }
}

function vehiclePageHeader(config: {
    title: Html;
    pageActions: Html[];
    vehicle: Vehicle;
}): Html {
    const vehiclePhotoCount = config.vehicle.photocount.value;
    const vehiclePhoto = config.vehicle.photo.value;
    const vehicleName = VehicleUtil.toName(config.vehicle);
    const vehicleSummary = VehicleUtil.toSummary(config.vehicle);
    const vehiclePrice = config.vehicle.price.asking.value;
    const vehicleDaysInStock = config.vehicle.daysinstock.amount.value;
    const vehicleDealer = config.vehicle.dealer.name.value;
    const vehicleAutoTrackLink = config.vehicle.aturl.value;
    const vehicleVideo = config.vehicle.video.value;

    return html`
        <ata-vehicle-page-header>
            <ata-vertial-layout slot="image">
                <ata-vehicle-image src="${vehiclePhoto}">
                    <ata-horizontal-layout slot="photoCount" gap="small">
                        ${vehicleVideo.length > 0
                            ? html`<span style="width: 2rem;"
                                  >${Icon.video()}</span
                              >`
                            : nothing}
                        <span style="width: 2rem;">${Icon.camera()}</span>
                        ${vehiclePhotoCount}
                    </ata-horizontal-layout>
                </ata-vehicle-image>
            </ata-vertial-layout>

            <ata-horizontal-layout align="fill" gap="medium">
                <ata-vertical-layout gap="medium">
                    <ata-big-heading>${vehicleName}</ata-big-heading>
                    <p>${vehicleSummary}</p>

                    <ata-vertical-layout>
                        <p>${vehicleDealer}</p>
                        <a href="${vehicleAutoTrackLink}" target="_blank">
                            Bekijk advertentie op AutoTrack
                        </a>
                    </ata-vertical-layout>
                </ata-vertical-layout>

                <ata-vertical-layout gap="medium" align="end start">
                    <ata-big-heading>
                        ${Format.currency(Number(vehiclePrice))}
                    </ata-big-heading>
                    <p>
                        ${intlText(
                            `Statijd: {value, plural, =1 {1 dag} other {# dagen}}`,
                            {
                                value: Number(vehicleDaysInStock),
                            },
                        )}
                    </p>
                </ata-vertical-layout>
            </ata-horizontal-layout>
        </ata-vehicle-page-header>

        <ata-page-header>
            <ata-big-heading slot="title">${config.title}</ata-big-heading>

            <ata-horizontal-layout gap="small" slot="actions">
                ${config.pageActions}
            </ata-horizontal-layout>
        </ata-page-header>
    `;
}
