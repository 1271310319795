import { Html } from '../../Application';
import { html } from 'lit';

type Size = 'small' | 'medium' | 'large' | 'none';

export function padding(size: Size, content: Html): Html {
    return paddingEach(
        {
            blockStart: size,
            blockEnd: size,
            inlineStart: size,
            inlineEnd: size,
        },
        content,
    );
}

export function paddingBlockInline(
    size: { block: Size; inline: Size },
    content: Html,
): Html {
    return paddingEach(
        {
            blockStart: size.block,
            blockEnd: size.block,
            inlineStart: size.inline,
            inlineEnd: size.inline,
        },
        content,
    );
}

export function paddingEach(
    size: {
        blockStart: Size;
        blockEnd: Size;
        inlineStart: Size;
        inlineEnd: Size;
    },
    content: Html,
): Html {
    const padding = `
        padding: 
            ${toSizeCssVariable(size.blockStart)} 
            ${toSizeCssVariable(size.inlineEnd)}
            ${toSizeCssVariable(size.blockEnd)}
            ${toSizeCssVariable(size.inlineStart)}; 
    `;

    return html`<ata-spacing style="display: block; ${padding}">
        ${content}
    </ata-spacing>`;
}

function toSizeCssVariable(size: Size): string {
    switch (size) {
        case 'small':
            return 'var(--dimensions--spacing--small)';
        case 'medium':
            return 'var(--dimensions--spacing--medium)';
        case 'large':
            return 'var(--dimensions--spacing--large)';
        case 'none':
            return '0';
    }
}
