import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators';

declare global {
    interface HTMLElementTagNameMap {
        'ata-navigation-item-with-sub': AtaNavigationItemWithSub;
    }
}

/**
 * @slot icon
 * @slot title
 * @slot @default - Sub navigation items
 */
@customElement('ata-navigation-item-with-sub')
export default class AtaNavigationItemWithSub extends LitElement {
    static styles = css`
        summary {
            display: grid;
            grid-template-columns: 2rem 1fr;
            align-items: center;
            gap: 3rem;
            padding: 2rem;
            text-decoration: none;
            color: currentColor;
        }

        :host([active]) details {
            background-color: var(
                --ata--app--navigation--backgroundColor--active
            );
            color: var(--ata--app--navigation--foregroundColor--active);
            fill: var(--ata--app--navigation--foregroundColor--active);
        }

        .navigationItem__sub {
            display: flex;
            flex-direction: column;
            padding-block-end: 1rem;
        }

        .navigationItem__sub ::slotted(a) {
            display: block;
            padding: 2rem 2rem 2rem 7rem;
            text-decoration: none !important;
            color: currentColor !important;
        }
    `;

    @property({ type: Boolean })
    active: boolean = false;

    attributeChangedCallback(
        name: string,
        _old: string | null,
        value: string | null,
    ): void {
        super.attributeChangedCallback(name, _old, value);

        if (name === 'active') {
            this.requestUpdate(name, _old);
        }
    }

    render(): TemplateResult {
        const isActive = this.getAttribute('active') !== null;

        return html`
            <details ?open="${isActive}">
                <summary>
                    <slot name="icon"></slot>
                    <slot name="title">No text</slot>
                </summary>

                <div class="navigationItem__sub">
                    <slot></slot>
                </div>
            </details>
        `;
    }
}
