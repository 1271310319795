type Path = string[];
export type Decoder<T> = (json: unknown, path?: Path) => T;

export function succeed<T>(value: T): Decoder<T> {
    return () => value;
}

export function literal<T>(literal: T): Decoder<T> {
    return (json, path: Path = []) => {
        if (json !== literal) {
            throw new Error(
                `Expected ${literal}, got ${json} at ${path.join('.')}`,
            );
        }

        return json as T;
    };
}

export const string: Decoder<string> = (
    json: unknown,
    path: Path = [],
): string => {
    if (typeof json !== 'string') {
        throw new Error(
            `Expected string, got ${typeof json} at ${path.join('.')}`,
        );
    }

    return json;
};

export const number: Decoder<number> = (
    json: unknown,
    path: Path = [],
): number => {
    if (typeof json !== 'number') {
        throw new Error(
            `Expected number, got ${typeof json} at ${path.join('.')}`,
        );
    }

    return json;
};

export const bool: Decoder<boolean> = (
    json: unknown,
    path: Path = [],
): boolean => {
    if (typeof json !== 'boolean') {
        throw new Error(
            `Expected boolean, got ${typeof json} at ${path.join('.')}`,
        );
    }

    return json;
};

export function nullable<T>(decoder: Decoder<T>): (json: unknown) => T | null {
    return (json) => {
        if (json === null) {
            return null;
        }

        return decoder(json);
    };
}

export function array<T>(decoder: Decoder<T>): (json: unknown) => T[] {
    return (json, path: Path = []) => {
        if (!Array.isArray(json)) {
            throw new Error(
                `Expected array, got ${typeof json} at ${path.join('.')}`,
            );
        }

        return json.map((value, index) =>
            decoder(value, [...path, String(index)]),
        );
    };
}

export function keyValuePairs<T>(
    decoder: Decoder<T>,
): (json: unknown) => Record<string, T> {
    return (json, path: Path = []) => {
        if (typeof json !== 'object' || json === null) {
            throw new Error(
                `Expected object, got ${typeof json} at ${path.join('.')}`,
            );
        }

        const result: { [key: string]: T } = {};

        for (const key in json) {
            if (!hasKey(key, json)) {
                throw new Error(
                    `Expected key \`${key}\` in object at ${path.join('.')}`,
                );
            }
            result[key] = decoder(json[key], [...path, key]);
        }

        return result;
    };
}

export function record<T extends Record<string, Decoder<unknown>>>(
    decoders: T,
): Decoder<{ [K in keyof T]: ReturnType<T[K]> }> {
    type ReturnValue = { [K in keyof T]: ReturnType<T[K]> };

    return (json: unknown, path: Path = []) => {
        if (typeof json !== 'object' || json === null) {
            throw new Error(
                `Expected an object for record decoding  at ${path.join('.')}`,
            );
        }

        const result: Partial<ReturnValue> = {};

        for (const key in decoders) {
            const fieldValue = (json as Record<string, unknown>)[key];
            const decoder = decoders[key];

            result[key] = decoder(fieldValue, [...path, key]) as ReturnType<
                T[typeof key]
            >;
        }

        return result as ReturnValue;
    };
}

export function field<T>(key: string, decoder: Decoder<T>): Decoder<T> {
    return (json: unknown, path: Path = []) => {
        if (typeof json !== 'object' || json === null) {
            throw new Error(
                `Expected object, got ${typeof json} at ${path.join('.')}`,
            );
        }

        if (!(key in json)) {
            throw new Error(
                `Expected key ${key} in object at ${path.join('.')}`,
            );
        }

        return decoder((json as Record<string, unknown>)[key], [...path, key]);
    };
}

export function at<T>(key: string[], decoder: Decoder<T>): Decoder<T> {
    return (json: unknown, path: Path = []) => {
        const [head, ...tail] = key;

        if (tail.length === 0) {
            return field(head, decoder)(json, path);
        }

        if (!(typeof json === 'object' && json !== null)) {
            throw new Error(
                `Expected object, got ${typeof json} at ${path.join('.')}`,
            );
        }

        if (!hasKey(head, json)) {
            throw new Error(
                `Expected key ${head} in object at ${path.join('.')}`,
            );
        }

        return at(tail, decoder)(json[head], [...path, head]);
    };
}

export function index<T>(i: number, decoder: Decoder<T>): Decoder<T> {
    return (json, path: Path = []) => {
        if (!Array.isArray(json)) {
            throw new Error(
                `Expected array, got ${typeof json} at ${path.join('.')}`,
            );
        }

        if (i >= json.length) {
            throw new Error(
                `Expected array of length ${i + 1}, got ${
                    json.length
                } at ${path.join('.')}`,
            );
        }

        return decoder(json[i], [...path, String(i)]);
    };
}

type UnionOfDecoderTypes<T extends Decoder<unknown>[]> =
    T[number] extends Decoder<infer U> ? U : never;

export function oneOf<T extends Array<Decoder<unknown>>>(
    decoders: T,
): Decoder<UnionOfDecoderTypes<T>> {
    return (json: unknown, path: Path = []) => {
        const errors: string[] = [];

        for (const decoder of decoders) {
            try {
                return decoder(json, path) as never;
            } catch (error) {
                errors.push((error as Error).message);
            }
        }

        throw new Error(
            `All decoders failed at ${path.join('.')}:\n\n\t${errors.join(
                '\n\n\t',
            )} `,
        );
    };
}

export function map<R, D extends Decoder<unknown>[]>(
    decoders: [...D],
    fn: (
        ...decodedArgs: {
            [K in keyof D]: D[K] extends Decoder<infer U> ? U : never;
        }
    ) => R,
): Decoder<R> {
    return (json: unknown, path: Path = []) =>
        fn(
            ...(decoders.map((d) => d(json, path)) as {
                [K in keyof D]: D[K] extends Decoder<infer U> ? U : never;
            }),
        );
}

/**
 * Checks if a key is in an object. This is a type guard.
 */
function hasKey<T extends object>(
    key: string | number | symbol,
    json: T,
): key is keyof T {
    return key in json;
}
