import { html } from 'lit';
import { Html } from '../../Application';
import '../Molecules/AtaNavigationItem';
import Icon from '../Atoms/Icon';
import { Route } from '../../Route';
import { navigation, NavigationItems } from './Navigation';

export default function taxationNavigation(
    isExpanded: boolean,
    activeRoute: Route,
    vehicleId: string,
    licensePlate: string,
    onNavigate: (href: string) => void,
): Html {
    return navigation({
        activeRoute,
        onNavigate,
        isExpanded,
        header: html`<ata-navigation-header>
            <span slot="icon">${Icon.calc()}</span>
            <span slot="title">Taxatie: ${licensePlate}</span>
        </ata-navigation-header>`,
        items: navigationItems(vehicleId),
    });
}

function navigationItems(vehicleId: string): NavigationItems {
    return [
        {
            id: 'TaxationDashboard',
            label: 'Dashboard',
            href: `/taxatie/${vehicleId}/dashboard`,
            icon: Icon.dashboard(),
        },
        {
            label: "Auto's vergelijken",
            icon: Icon.concurrentie(),
            items: [
                {
                    id: 'TaxationComparisonOverview',
                    label: 'Overzicht',
                    href: `/taxatie/${vehicleId}/vergelijk/overzicht`,
                },
                {
                    id: 'TaxationComparisonPriceAnalysis',
                    label: 'Prijsanalyse',
                    href: `/taxatie/${vehicleId}/vergelijk/prijsanalyse`,
                },
                {
                    id: 'TaxationComparisonPriceHistory',
                    label: 'Prijshistorie',
                    href: `/taxatie/${vehicleId}/vergelijk/prijshistorie`,
                },
                {
                    id: 'TaxationComparisonGeographic',
                    label: 'Geografisch',
                    href: `/taxatie/${vehicleId}/vergelijk/geografisch`,
                },
            ],
        },
        {
            id: 'TaxationSettings',
            label: 'Eigenschappen',
            href: `/taxatie/${vehicleId}/settings`,
            icon: Icon.settings(),
        },
    ];
}
