export default {
    getValue,
    set,
    clearExpired,
};

/**
 * A map that stores a value and the date it was created.
 * The entries can be cleared by a predicate, see {@link clearExpired}.
 *
 * Note: Purposely didn't create a class for this because `structeredClone` requires transferable objects.
 */
export type CacheMap<key, value> = Map<key, [value: value, createdOn: Date]>;

export function getValue<key, value>(
    key: key,
    map: CacheMap<key, value>,
): value | undefined {
    return map.get(key)?.[0];
}

export function set<key, value>(
    key: key,
    value: value,
    createdOn: Date,
    map: CacheMap<key, value>,
): CacheMap<key, value> {
    map.set(key, [value, createdOn]);
    return map;
}

export function clearExpired<key, value>(
    expirationPredicate: (value: value, createdOn: Date) => boolean,
    map: CacheMap<key, value>,
): CacheMap<key, value> {
    for (const [key, [value, createdOn]] of map.entries()) {
        if (expirationPredicate(value, createdOn)) {
            map.delete(key);
        }
    }
    return map;
}
