import { html, nothing, render, TemplateResult } from 'lit';
import { Signal } from './Signal';
import { StrResult } from '@lit/localize';

type Application<msg, model> = {
    subscribe: (fn: (state: model) => void) => void;
    send: (msg: msg) => void;
};
type Msg = { type: string; payload?: unknown };
export type Cmd<msg> = Promise<msg>[];
export type Html = TemplateResult | StrResult | typeof nothing;
export type ApplicationDocument = { title: string; body: Html };
export type Dispatch<msg> = <
    Args extends never[],
    Fn extends (...args: Args) => msg,
>(
    msg: Fn,
) => Fn extends (...args: infer Args) => msg ? (...args: Args) => void : never;

export function application<msg extends Msg, model>(config: {
    root: HTMLElement;
    init: [model, Cmd<msg>];
    view: (dispatch: Dispatch<msg>, model: model) => ApplicationDocument;
    update: (msg: msg, model: model) => [model, Cmd<msg>];
}): Application<msg, model> {
    const state = new Signal<model>(config.init[0]);
    const msgQueue: msg[] = [];
    let isProcessing = false;

    handleCmd(config.init[1]);

    async function processQueue(): Promise<void> {
        if (isProcessing || msgQueue.length === 0) return;

        isProcessing = true;

        const msg = msgQueue.shift()!;
        const [newModel, cmd] = config.update(
            msg,
            structuredClone(state.value),
        );
        state.set(newModel);

        if (cmd) {
            handleCmd(cmd);
        }

        isProcessing = false;

        performance.mark('Processed');

        if (msgQueue.length > 0) {
            processQueue();
        }
    }

    function handleCmd(cmd: Cmd<msg>): void {
        for (const task of cmd) {
            task.then(dispatch);
        }
    }

    function dispatch(msg: msg): void {
        msgQueue.push(msg);
        processQueue();
    }

    function renderView(theState: model): void {
        const execRender = (): void => {
            try {
                const theDocument = config.view(
                    (toMsg) =>
                        (...args) =>
                            dispatch(toMsg(...args)),
                    theState,
                );

                render(theDocument.body, config.root);
                document.title = theDocument.title;
            } catch (error) {
                const errorMessage =
                    error instanceof Error ? error.stack : error;

                render(
                    html`<style>
                            body {
                                background: black !important;
                                font-size: 2em !important;
                                padding: 4rem !important;
                            }
                        </style>
                        <pre style="color: #FF2222"> ${errorMessage} </pre> `,
                    config.root,
                );

                console.error(error);
            }
        };

        if (!('startViewTransition' in document)) {
            execRender();
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.startViewTransition(() => execRender());
    }

    state.subscribe(renderView);
    renderView(state.value);

    return {
        subscribe: (fn: (state: model) => void) => state.subscribe(fn),
        send: (msg: msg) => dispatch(msg),
    };
}
