import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators';

declare global {
    interface HTMLElementTagNameMap {
        'ata-page-header': AtaPageHeader;
    }
}

/**
 * @slot title
 * @slot subtitle
 * @slot actions
 */
@customElement('ata-page-header')
export default class AtaPageHeader extends LitElement {
    static styles = css`
        :host {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-areas:
                'title actions'
                'subtitle subtitle';
            gap: var(--dimensions--spacing--small)
                var(--dimensions--spacing--medium);
            padding: var(--dimensions--spacing--large)
                var(--dimensions--spacing--large);
        }

        .pageHeader__title {
            grid-area: title;
        }

        .pageHeader__subtitle {
            grid-area: subtitle;
        }

        .pageHeader__actions {
            grid-area: actions;
            align-self: center;
        }
    `;

    render(): TemplateResult {
        return html`
            <div class="pageHeader__title">
                <slot name="title"></slot>
            </div>

            <div class="pageHeader__subtitle">
                <slot name="subtitle"></slot>
            </div>

            <div class="pageHeader__actions">
                <slot name="actions"></slot>
            </div>
        `;
    }
}
