import { customElement } from 'lit/decorators';
import IntlMessageFormat from 'intl-messageformat';
import { html } from 'lit';
import { Html } from '../../Application';

export default {
    intlText,
};

export function intlText(message: string, data: Record<string, unknown>): Html {
    return html`${new IntlMessageFormat(message, 'nl-NL').format(data)}`;
}

@customElement('intl-text')
export class IntlText extends HTMLElement {
    private observer: MutationObserver = new MutationObserver(
        this.formatTextContent.bind(this),
    );

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback(): void {
        this.formatTextContent();

        this.observer.observe(this, {
            characterData: true,
            childList: true,
            attributes: true,
        });
    }

    disconnectedCallback(): void {
        this.observer.disconnect();
    }

    protected formatTextContent(): void {
        let text = new IntlMessageFormat(
            this.textContent ?? '',
            'nl-NL',
        ).format(this.dataset);

        if (Array.isArray(text)) {
            text = text.join('');
        }

        if (this.shadowRoot) {
            this.shadowRoot.textContent = text ?? '';
        }
    }
}
