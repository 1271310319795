import {
    Scenario,
    ScenarioCluster,
    ScenarioClusterDraft,
    ScenarioDraft,
} from '../Model';

export function create(): ScenarioDraft {
    return {
        name: '',
        clusters: [createCluster()],
    };
}

function createCluster(): ScenarioClusterDraft {
    return {
        name: '',
        dealers: [],
    };
}

export function setName(
    scenario: Scenario | ScenarioDraft,
    title: string,
): ScenarioDraft {
    const nextScenario: ScenarioDraft = structuredClone(scenario);

    nextScenario['name'] = title;

    return nextScenario;
}

export function addCluster(scenario: Scenario | ScenarioDraft): ScenarioDraft {
    const nextScenario: ScenarioDraft = structuredClone(scenario);

    nextScenario['clusters'].push(createCluster());

    return nextScenario;
}

export function updateCluster(
    scenario: Scenario | ScenarioDraft,
    index: number,
    fn: (cluster: ScenarioClusterDraft) => ScenarioClusterDraft,
): ScenarioDraft {
    const nextScenario: ScenarioDraft = structuredClone(scenario);

    if (index in nextScenario.clusters) {
        nextScenario.clusters[index] = fn(nextScenario.clusters[index]);
    }

    return nextScenario;
}

export function removeCluster(
    scenario: Scenario | ScenarioDraft,
    index: number,
): ScenarioDraft {
    const nextScenario: ScenarioDraft = structuredClone(scenario);

    if (index in nextScenario.clusters) {
        nextScenario.clusters.splice(index, 1);
    }

    return nextScenario;
}

export function setClusterName(
    cluster: ScenarioCluster | ScenarioClusterDraft,
    name: string,
): ScenarioClusterDraft {
    const nextCluster: ScenarioClusterDraft = structuredClone(cluster);

    nextCluster.name = name;

    return nextCluster;
}

export function addDealerToCluster(
    cluster: ScenarioCluster | ScenarioClusterDraft,
    dealerId: string,
): ScenarioClusterDraft {
    const nextCluster: ScenarioClusterDraft = structuredClone(cluster);

    nextCluster.dealers.push(dealerId);

    return nextCluster;
}

export function removeDealerFromCluster(
    cluster: ScenarioCluster | ScenarioClusterDraft,
    dealerId: string,
): ScenarioClusterDraft {
    const nextCluster: ScenarioClusterDraft = structuredClone(cluster);

    nextCluster.dealers = nextCluster.dealers.filter((id) => id !== dealerId);

    return nextCluster;
}

export function getAllDealers(scenario: Scenario | ScenarioDraft): string[] {
    return scenario.clusters.flatMap((cluster) => cluster.dealers);
}
