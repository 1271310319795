import { Html } from '../../Application';
import { Account, DeviceLayout } from '../../Model';
import { html, nothing } from 'lit';
import { join } from 'lit/directives/join';
import '../Layouts/AtaLayoutShell';
import '../Organisms/AtaNavigation';
import '../Organisms/AtaNavigationGroup';
import '../Atoms/AtaBigHeading';
import '../Atoms/AtaFilledButton';
import '../Organisms/AtaPageHeader';
import '../Layouts/AtaHorizontalLayout';
import mainNavigation from './MainNavigation';
import { intlText } from '../Atoms/IntlText';
import pageNavigation from './PageNavigation';
import { padding, paddingBlockInline, paddingEach } from './Padding';
import appBar from './AppBar';
import { button } from './Button';
import Icon from '../Atoms/Icon';
import taxationWidget from './TaxationWidget';
import appFooter from './AppFooter';
import { link } from './Link';
import { Route } from '../../Route';
import { version } from '../../Environment';

export default function mainShell(config: {
    deviceLayout: DeviceLayout;
    pageTitle: string;
    pageActions: Html[];
    pageNavigations?: {
        previous?: { href: string; label: string };
        next?: { href: string; label: string };
    };
    content: Html;
    account: Account;
    activeRoute: Route;
    isNavigationVisible: boolean;
    onNavigate: (href: string) => void;
    onToggleNavigation: () => void;
    stockCount: { total: number; selected: number } | null;
    dealerCount: { total: number; selected: number } | null;
    hasSettings?: boolean;
    taxationWidget?: {
        licensePlate: string;
        mileage: string;
        errorMessage: string | null;
        onLicensePlateChange: (value: string) => void;
        onMileageChange: (value: string) => void;
        onSubmit: () => void;
        onClearErrorMessage: () => void;
    };
}): Html {
    return html`
        <ata-layout-shell ?navigation-visible="${config.isNavigationVisible}">
            <div slot="header">
                ${viewAppBar(config.deviceLayout, config.onToggleNavigation)}
            </div>

            <div
                slot="navigation"
                style="block-size: 100%; background: var(--ata--app--navigation--backgroundColor); display: flex; flex-direction: column; overflow: hidden;"
            >
                <ata-navigation-group style="flex: 1; overflow: hidden;">
                    ${mainNavigation(
                        config.account,
                        config.activeRoute,
                        true,
                        config.onNavigate,
                    )}
                </ata-navigation-group>

                ${config.taxationWidget !== undefined
                    ? html`
                          <div
                              style="flex: 0; position: sticky; inset: auto 0 0 0; padding: var(--dimensions--spacing--medium); box-sizing: border-box;"
                          >
                              ${taxationWidget(config.taxationWidget)}
                          </div>
                      `
                    : nothing}
            </div>

            ${pageHeader({
                onNavigate: config.onNavigate,
                title: html`${config.pageTitle}`,
                account: config.account,
                actions: config.pageActions,
                ...(config.hasSettings === true
                    ? {
                          stockCount: null,
                          dealerCount: null,
                      }
                    : {
                          stockCount: config.stockCount,
                          dealerCount: config.dealerCount,
                      }),
            })}

            <main style="position: relative"> ${config.content} </main>

            ${config.pageNavigations
                ? paddingEach(
                      {
                          blockStart: 'large',
                          blockEnd: 'none',
                          inlineStart: 'none',
                          inlineEnd: 'none',
                      },
                      html`<hr />`,
                  )
                : nothing}
            ${padding(
                'large',
                pageNavigation(
                    {
                        previous: config.pageNavigations?.previous,
                        next: config.pageNavigations?.next,
                    },
                    config.onNavigate,
                ),
            )}
            ${appFooter(config.account.lastUpdate)}
        </ata-layout-shell>
    `;
}

function viewAppBar(
    deviceLayout: DeviceLayout,
    onToggleNavigation: () => void,
): Html {
    switch (deviceLayout) {
        case 'Desktop': {
            return appBar({
                start: paddingBlockInline(
                    { block: 'none', inline: 'medium' },
                    html`<img
                        loading="lazy"
                        src="https://analytics.autotrack.nl/assets/images/autotrack-analytics.png"
                        style="block-size: 3rem"
                        title="AutoTrack Analytics v${version}"
                    />`,
                ),
            });
        }

        case 'Mobile': {
            return appBar({
                center: paddingBlockInline(
                    { block: 'none', inline: 'medium' },
                    html`<img
                        loading="lazy"
                        src="https://analytics.autotrack.nl/assets/images/autotrack-analytics.png"
                        style="block-size: 3rem"
                        title="AutoTrack Analytics v${version}"
                    />`,
                ),
                end: button({
                    theme: 'Secondary',
                    content: Icon.hamburger(),
                    onClick: onToggleNavigation,
                }),
            });
        }
    }
}

export function pageHeader(config: {
    onNavigate: (href: string) => void;
    title: Html;
    account: Account;
    actions: Html[];
    stockCount: { total: number; selected: number } | null;
    dealerCount: { total: number; selected: number } | null;
}): Html {
    return html`
        <ata-page-header>
            <ata-big-heading slot="title">${config.title}</ata-big-heading>
            <span slot="subtitle"
                >${toPageHeaderSubtitle(
                    config.onNavigate,
                    config.account,
                    config.stockCount,
                    config.dealerCount,
                )}</span
            >
            <ata-horizontal-layout gap="small" slot="actions">
                ${config.actions}
            </ata-horizontal-layout>
        </ata-page-header>
    `;
}

function toPageHeaderSubtitle(
    onNavigate: (href: string) => void,
    account: Account,
    stockCount: { total: number; selected: number } | null,
    dealerCount: { total: number; selected: number } | null,
): Html {
    switch (account.type) {
        case 'Dealer': {
            if (stockCount === null) {
                return nothing;
            }

            return toPageHeaderStockCount(onNavigate, stockCount);
        }

        case 'Holding':
        case 'Group': {
            const sections = [
                stockCount !== null
                    ? toPageHeaderStockCount(onNavigate, stockCount)
                    : null,
                dealerCount !== null
                    ? toPageHeaderDealerCount(onNavigate, dealerCount)
                    : null,
            ];

            return html`${join(sections.filter(Boolean), html` | `)}`;
        }
    }
}

function toPageHeaderStockCount(
    onNavigate: (href: string) => void,
    data: {
        total: number;
        selected: number;
    },
): Html {
    const selectionText = intlText(
        "Selectie: {selected} van {total, plural, =1 {{total} auto} other {{total} auto's}}",
        {
            total: data.total,
            selected: data.selected,
        },
    );

    return html`
        <span style="white-space: nowrap;">
            <span> ${selectionText} </span>
            - ${link('/instellingen/voorraad', onNavigate, html`Aanpassen`)}
        </span>
    `;
}

function toPageHeaderDealerCount(
    onNavigate: (href: string) => void,
    data: {
        total: number;
        selected: number;
    },
): Html {
    const selectionText = intlText(
        'Selectie vestigingen: {selected} van {total}',
        {
            total: data.total,
            selected: data.selected,
        },
    );

    return html`
        <span style="white-space: nowrap;">
            <span> ${selectionText} </span>
            -
            ${link(
                '/instellingen/vestigingen',
                onNavigate,
                html`Samenstelling aanpassen`,
            )}
        </span>
    `;
}
