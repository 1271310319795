import '../Atoms/AtaBigHeading';
import '../Atoms/AtaMediumHeading';
import '../Atoms/AtaSmallHeading';
import { Html } from '../../Application';
import { html } from 'lit';

export function bigHeading(content: Html): Html {
    return html`<ata-big-heading>${content}</ata-big-heading>`;
}

export function mediumHeading(content: Html): Html {
    return html`<ata-medium-heading>${content}</ata-medium-heading>`;
}

export function smallHeading(content: Html): Html {
    return html`<ata-small-heading>${content}</ata-small-heading>`;
}
