import { customElement } from 'lit/decorators';
import { css, html, LitElement, TemplateResult } from 'lit';
import AtaNavigation from './AtaNavigation';

declare global {
    interface HTMLElementTagNameMap {
        'ata-navigation-group': AtaNavigationGroup;
    }
}

@customElement('ata-navigation-group')
export default class AtaNavigationGroup extends LitElement {
    static styles = css`
        :host {
            display: flex;
            flex-direction: row;
            block-size: 100%;
        }

        ::slotted(ata-navigation) {
            flex: 0 0 7rem;
            min-inline-size: 7rem;
            transition: flex var(--motion--duration--long)
                var(--motion--easing--inOut);
            white-space: nowrap;
            overflow-x: hidden;
        }

        ::slotted(ata-navigation:not(:first-child)) {
            border-inline-start: 1px solid black;
        }

        ::slotted(ata-navigation:not([aria-expanded='true'])) {
            cursor: pointer;
        }

        ::slotted(ata-navigation[aria-expanded='true']) {
            flex: 1;
        }
    `;

    private navigations: AtaNavigation[] = [];

    render(): TemplateResult {
        return html` <slot @slotchange="${this.onSlotChange}"></slot> `;
    }

    private onSlotChange(): void {
        this.setNavigationListeners();
    }

    firstUpdated(): void {
        this.setNavigationListeners();
    }

    private setNavigationListeners(): void {
        for (const navigation of this.navigations) {
            navigation.removeEventListener('click', expandNavigation);
        }

        this.navigations = [];

        const navigations =
            this.shadowRoot?.querySelector('slot')?.assignedElements() ?? [];

        for (const navigation of navigations) {
            if (navigation instanceof AtaNavigation) {
                navigation.addEventListener('click', expandNavigation);
                this.navigations.push(navigation);
            }
        }

        function expandNavigation(this: AtaNavigation): void {
            if (this.getAttribute('aria-expanded') === 'true') {
                return;
            }

            for (const navigation of navigations) {
                navigation.removeAttribute('aria-expanded');
            }

            this.setAttribute('aria-expanded', 'true');
        }
    }
}
