import { customElement } from 'lit/decorators';
import { css, html, LitElement, TemplateResult } from 'lit';

declare global {
    interface HTMLElementTagNameMap {
        'ata-layout-shell': AtaLayoutShell;
    }
}

/**
 * @slot header
 * @slot navigation
 * @slot @default - The content of the page
 */
@customElement('ata-layout-shell')
export default class AtaLayoutShell extends LitElement {
    static styles = css`
        :host {
            ---header-height: 7rem;
            ---navigation-width: 40rem;

            display: grid;
            grid-template-columns: 100cqi var(---navigation-width, auto);
            grid-template-rows: var(---header-height, 0) minmax(
                    calc(100cqb - var(---header-height)),
                    max-content
                );
            grid-template-areas: 'header navigation' 'content navigation';
            grid-gap: 0;
            transition: translate var(--motion--duration--long, 0s) ease-in-out;
        }

        :host([navigation-visible]) {
            translate: calc(-1 * var(---navigation-width, 0));
        }

        .shell__header {
            grid-area: header;
            position: sticky;
            top: 0;
            z-index: 1;
        }

        .shell__navigation {
            grid-area: navigation;
            position: sticky;
            top: 0;
            block-size: 100cqb;
            overflow: auto;
        }

        .shell__content {
            grid-area: content;
            position: relative;
        }

        @container (min-width: 1000px) {
            :host {
                grid-template-columns: var(---navigation-width, auto) 1fr;
                grid-template-rows: 7rem minmax(calc(100cqb - 7rem), 1fr);
                grid-template-areas: 'header header' 'navigation content';
                grid-gap: 0;
            }

            :host([navigation-visible]) {
                translate: 0;
            }

            .shell__navigation {
                top: var(---header-height, 0);
                block-size: calc(100cqb - var(---header-height, 0));
            }
        }
    `;

    render(): TemplateResult {
        return html`
            <div class="shell__header">
                <slot name="header"></slot>
            </div>

            <div class="shell__navigation">
                <slot name="navigation"></slot>
            </div>

            <div class="shell__content">
                <slot></slot>
            </div>
        `;
    }
}
