import { customElement } from 'lit/decorators';
import { css, html, LitElement, TemplateResult } from 'lit';

declare global {
    interface HTMLElementTagNameMap {
        'ata-small-heading': AtaSmallHeading;
    }
}

@customElement('ata-small-heading')
export default class AtaSmallHeading extends LitElement {
    static styles = css`
        :host {
            font-size: 1em;
            line-height: 1.1em;
            letter-spacing: 1px;
            margin: 0;
            text-transform: uppercase;
            font-weight: bold;
        }
    `;

    render(): TemplateResult {
        return html`<slot></slot>`;
    }
}
