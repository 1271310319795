import { customElement } from 'lit/decorators';
import { css } from 'lit';
import AtaButton, { ataButtonStyles } from './AtaButton';

declare global {
    interface HTMLElementTagNameMap {
        'ata-text-button': AtaTextButton;
    }
}

@customElement('ata-text-button')
export default class AtaTextButton extends AtaButton {
    static styles = [
        ataButtonStyles,
        css`
            a,
            button {
                ---ataButton--backgroundColor: transparent;
                ---ataButton--foregroundColor: var(--ata--button--color);
                ---ataButton--borderColor: transparent;
            }
        `,
    ];
}
