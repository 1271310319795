import { html, nothing } from 'lit';
import { Html } from '../../Application';
import '../Atoms/AtaTextButton';
import '../Atoms/AtaStrokedButton';
import '../Atoms/AtaFilledButton';

type ButtonTheme = 'Primary' | 'Secondary' | 'Success' | 'Warning' | 'Danger';

type ButtonBaseConfig = {
    content: Html;
    inProgress?: boolean;
    theme: ButtonTheme;
};

type ButtonConfig = ButtonBaseConfig &
    (
        | {
              type?: 'button';
              onClick?: () => void;
          }
        | {
              type: 'submit' | 'reset';
              disabled?: boolean;
          }
        | {
              href: string;
              onClick?: (href: string) => void;
          }
    );

export function button(config: ButtonConfig): Html {
    return internalButton({
        content: config.content,
        onClick: toOnClick(config),
        inProgress: config.inProgress,
        isDisabled: toDisabled(config),
        dense: false,
        appearance: 'Text',
        theme: config.theme,
        type: toType(config),
        href: toHref(config),
    });
}

export function denseButton(config: ButtonConfig): Html {
    return internalButton({
        content: config.content,
        onClick: toOnClick(config),
        inProgress: config.inProgress,
        isDisabled: toDisabled(config),
        dense: true,
        appearance: 'Text',
        theme: config.theme,
        type: toType(config),
        href: toHref(config),
    });
}

export function filledButton(config: ButtonConfig): Html {
    return internalButton({
        content: config.content,
        onClick: toOnClick(config),
        inProgress: config.inProgress,
        isDisabled: toDisabled(config),
        dense: false,
        appearance: 'Filled',
        theme: config.theme,
        type: toType(config),
        href: toHref(config),
    });
}

export function denseFilledButton(config: ButtonConfig): Html {
    return internalButton({
        content: config.content,
        onClick: toOnClick(config),
        inProgress: config.inProgress,
        isDisabled: toDisabled(config),
        dense: true,
        appearance: 'Filled',
        theme: config.theme,
        type: toType(config),
        href: toHref(config),
    });
}

export function strokedButton(config: ButtonConfig): Html {
    return internalButton({
        content: config.content,
        onClick: toOnClick(config),
        inProgress: config.inProgress,
        isDisabled: toDisabled(config),
        dense: false,
        appearance: 'Stroked',
        theme: config.theme,
        type: toType(config),
        href: toHref(config),
    });
}

export function denseStrokedButton(config: ButtonConfig): Html {
    return internalButton({
        content: config.content,
        onClick: toOnClick(config),
        inProgress: config.inProgress,
        isDisabled: toDisabled(config),
        dense: true,
        appearance: 'Stroked',
        theme: config.theme,
        type: toType(config),
        href: toHref(config),
    });
}

function internalButton(config: {
    appearance: 'Text' | 'Stroked' | 'Filled';
    content: Html;
    inProgress?: boolean;
    theme: ButtonTheme;
    type: 'button' | 'submit' | 'reset';
    onClick?: (href?: string) => void;
    isDisabled: boolean;
    href?: string;
    dense: boolean;
}): Html {
    const isProgress = config?.inProgress === true;
    const onClick = config.onClick ?? ((): void => {});

    const themeClass: string = {
        Primary: 'primary',
        Secondary: 'secondary',
        Success: 'success',
        Warning: 'warning',
        Danger: 'danger',
    }[config.theme];

    switch (config.appearance) {
        case 'Text':
            return html`<ata-text-button
                theme="${themeClass}"
                ?disabled="${config.isDisabled}"
                ?dense="${config.dense}"
                ?progress="${isProgress}"
                href=${config.href ?? nothing}
                type="${config.type ?? 'button'}"
                @click="${(event: Event): void => {
                    if (config.href) {
                        event.preventDefault();
                        onClick(config.href);
                    } else {
                        onClick();
                    }
                }}"
            >
                ${config.content}
            </ata-text-button>`;

        case 'Stroked':
            return html`<ata-stroked-button
                theme="${themeClass}"
                ?disabled="${config.isDisabled}"
                ?dense="${config.dense}"
                ?progress="${isProgress}"
                href=${config.href ?? nothing}
                type="${config.type ?? 'button'}"
                @click="${(event: Event): void => {
                    if (config.href) {
                        event.preventDefault();
                        onClick(config.href);
                    } else {
                        onClick();
                    }
                }}"
            >
                ${config.content}
            </ata-stroked-button>`;

        case 'Filled':
            return html`<ata-filled-button
                theme="${themeClass}"
                ?disabled="${config.isDisabled}"
                ?dense="${config.dense}"
                ?progress="${isProgress}"
                href=${config.href ?? nothing}
                type="${config.type ?? 'button'}"
                @click="${(event: Event): void => {
                    if (config.href) {
                        event.preventDefault();
                        onClick(config.href);
                    } else {
                        onClick();
                    }
                }}"
            >
                ${config.content}
            </ata-filled-button>`;
    }
}

function toOnClick(
    config: ButtonConfig,
): ((href?: string) => void) | undefined {
    return 'onClick' in config
        ? (config.onClick as (href?: string) => void)
        : undefined;
}

function toDisabled(config: ButtonConfig): boolean {
    if (
        'type' in config &&
        (config.type === 'submit' || config.type === 'reset')
    ) {
        return config.disabled === true;
    }

    return 'onClick' in config && config.onClick === undefined;
}

function toType(config: ButtonConfig): 'button' | 'submit' | 'reset' {
    if ('type' in config) {
        return config.type ?? 'button';
    }

    return 'button';
}

function toHref(config: ButtonConfig): string | undefined {
    return 'href' in config ? config.href : undefined;
}
