import { Html } from '../../Application';
import { padding } from './Padding';
import {
    fillContainer,
    horizontalLayout,
    verticalLayout,
} from '../Atoms/Layout';
import numberInput from './NumberInput';
import { denseFilledButton } from './Button';
import Icon from '../Atoms/Icon';
import { smallHeading } from './Heading';
import { css, html, nothing } from 'lit';
import { declareStyle } from '../Util';
import licensePlateInput from './LicensePlateInput';

declareStyle(css`
    ata-taxation-widget {
        display: block;
        background: var(--color--neutral-3);
        color: var(--color--neutral-10);
        fill: var(--color--neutral-10);
        border-radius: var(--dimensions--borderRadius--small);
    }
`);

export default function taxationWidget(config: {
    licensePlate: string;
    mileage: string;
    errorMessage: string | null;
    onLicensePlateChange: (value: string) => void;
    onMileageChange: (value: string) => void;
    onSubmit: () => void;
    onClearErrorMessage: () => void;
}): Html {
    const onSubmit = (event: Event): void => {
        event.preventDefault();
        config.onSubmit();
    };

    return html`<ata-taxation-widget>
        <form @submit=${onSubmit}>
        ${padding(
            'medium',
            verticalLayout(['fill', 'start'], 'medium', [
                horizontalLayout(['start', 'start'], 'medium', [
                    html`<div style="inline-size: 2rem; aspect-ratio: 1">
                        ${Icon.calc()}
                    </div>`,
                    verticalLayout(['start', 'center'], 'small', [
                        smallHeading(html`Taxatie`),
                        html`<small>Vul kenteken en km-stand in</small>`,
                    ]),
                ]),

                horizontalLayout(['fill', 'center'], 'small', [
                    fillContainer(
                        licensePlateInput({
                            value: config.licensePlate,
                            name: 'taxation-licenseplate',
                            placeholder: 'Kenteken',
                            onChange: config.onLicensePlateChange,
                            errorMessage:
                                config.licensePlate === ''
                                    ? 'Vul kenteken in'
                                    : undefined,
                        }),
                    ),
                    fillContainer(
                        numberInput({
                            value: config.mileage,
                            name: 'taxation-mileage',
                            placeholder: 'Km-stand',
                            onChange: config.onMileageChange,
                            errorMessage:
                                config.mileage === 'Vul km-stand in'
                                    ? 'Required'
                                    : undefined,
                        }),
                    ),
                    denseFilledButton({
                        type: 'submit',
                        theme: 'Primary',
                        content: Icon.loop(),
                    }),
                ]),
                config.errorMessage
                    ? html`<div
                          style="
                            background: var(--color--danger); 
                            color: var(--color--neutral-10); 
                            padding: var(--dimensions--spacing--medium); 
                            border-radius: var(--dimensions--borderRadius--small)
                          "
                      >
                          <span>${config.errorMessage}</span>
                      </div>`
                    : nothing,
            ]),
        )}
        </form>
    </div>`;
}
