import { customElement } from 'lit/decorators';
import { css } from 'lit';
import AtaTwoDimensionalLayout, {
    alignmentCss,
    gapCss,
    horizontalAlignmentCss,
} from './AtaTwoDimensionalLayout';

declare global {
    interface HTMLElementTagNameMap {
        'ata-horizontal-layout': AtaHorizontalLayout;
    }
}

/**
 * @property align
 * @property gap
 * @slot @default
 */
@customElement('ata-horizontal-layout')
export default class AtaHorizontalLayout extends AtaTwoDimensionalLayout {
    static styles = [
        gapCss,
        alignmentCss,
        horizontalAlignmentCss,
        css`
            :host {
                display: flex;
                flex-direction: row;
            }
        `,
    ];
}
