import { html, LitElement, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators';
import { AtaInput, inputCss } from './AtaInput';
import { mixinDebouncedInput } from '../_mixins/debouncedInput';

declare global {
    interface HTMLElementTagNameMap {
        'ata-number-input': AtaNumberInput;
    }
}

const VALUE = Symbol('VALUE');

/**
 * A number input element replacing the native `<input>` element.
 *
 * @property disabled
 * @property readonly
 * @property required
 * @property min
 * @property max
 * @property step
 * @property placeholder
 * @property errorMessage
 * @property autocomplete
 * @property tabIndex
 * @property value
 * @property valueAsNumber
 * @property name
 * @property debounceDuration - The duration in milliseconds to debounce the input event (see `debouncedInput`).
 * @event input - Dispatched when the input value changes.
 * @event debouncedInput - Dispatched when the input value has been debounced (see `debounceDuration`).
 */
@customElement('ata-number-input')
export default class AtaNumberInput extends mixinDebouncedInput(AtaInput) {
    static readonly formAssociated = true;

    static override shadowRootOptions = {
        ...LitElement.shadowRootOptions,
        delegatesFocus: true,
    };

    static styles = [inputCss];

    @property({ type: Boolean, reflect: true })
    disabled = false;

    @property({ type: Boolean, reflect: true })
    readonly = false;

    @property({ type: Boolean, reflect: true })
    required = false;

    @property({ type: Number })
    step = 1;

    @property({ type: Number })
    min? = undefined;

    @property({ type: Number })
    max? = undefined;

    @property({ type: String })
    placeholder = '';

    @property({ type: String, attribute: 'error-message', noAccessor: true })
    errorMessage = '';

    @property({ type: String })
    autocomplete = 'off';

    @property({ type: Number, reflect: true, attribute: 'tabindex' })
    tabIndex = 0;

    [VALUE] = '';

    @property({ type: String })
    get value(): string {
        return this[VALUE];
    }

    set value(value: string) {
        this[VALUE] = value;
    }

    @property({ type: Number, attribute: undefined })
    get valueAsNumber(): number | null {
        const value = Number(this.value);
        return isNaN(value) ? null : value;
    }

    set valueAsNumber(value: number) {
        this.value = String(value);
    }

    @property({ type: String, noAccessor: true })
    get name(): string {
        return this.getAttribute('name') ?? '';
    }

    set name(name: string) {
        this.setAttribute('name', name);
    }

    get form(): HTMLFormElement | null {
        return this.internals.form;
    }

    protected get input(): HTMLInputElement | null {
        return this.renderRoot.querySelector('input');
    }

    protected internals: ElementInternals;

    constructor() {
        super();

        this.internals = this.attachInternals();
        this.internals.role = 'textbox';

        this.addEventListener('focus', this.onFocusEvent.bind(this));
        this.addEventListener('keypress', this.onKeyPressEvent.bind(this));
    }

    attributeChangedCallback(
        name: string,
        oldValue: string | null,
        value: string | null,
    ): void {
        if (name === 'name') {
            this.requestUpdate('name', oldValue);
            return;
        }

        if (name === 'error-message') {
            this.errorMessage = value ?? '';
            this.requestUpdate('errorMessage', oldValue);
            return;
        }

        super.attributeChangedCallback(name, oldValue, value);
    }

    updated(changedProperties: PropertyValues): void {
        super.updated(changedProperties);

        if (changedProperties.has('value')) {
            this.internals.setFormValue(this.value, this.value);
        }

        this.updateValidity();
    }

    formStateRestoreCallback(state: string): void {
        this.value = state;
    }

    formDisabledCallback(disabled: boolean): void {
        this.disabled = disabled;
    }

    formResetCallback(): void {
        this[VALUE] = '';
    }

    setCustomValidity(message: string): void {
        const previousMessage = this.errorMessage;
        this.errorMessage = message;
        this.requestUpdate('errorMessage', previousMessage);
    }

    updateValidity(): void {
        if (!this.internals) return;

        const inputEl = this.input;

        if (!inputEl) return;

        this.internals.setValidity(
            {
                badInput: inputEl.validity.badInput,
                customError:
                    this.errorMessage !== '' || inputEl.validity.customError,
                patternMismatch: inputEl.validity.patternMismatch,
                rangeOverflow: inputEl.validity.rangeOverflow,
                rangeUnderflow: inputEl.validity.rangeUnderflow,
                stepMismatch: inputEl.validity.stepMismatch,
                tooLong: inputEl.validity.tooLong,
                tooShort: inputEl.validity.tooShort,
                typeMismatch: inputEl.validity.typeMismatch,
                valueMissing: inputEl.validity.valueMissing,
            },
            this.errorMessage || this.input.validationMessage,
            this.input,
        );
    }

    protected onFocusEvent(): void {
        this.input?.focus();
    }

    protected onKeyPressEvent(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Enter': {
                this.internals.form?.requestSubmit();
                break;
            }
        }
    }

    protected override render(): TemplateResult {
        return html`
            <input
                type="number"
                ?disabled=${this.disabled}
                ?readonly=${this.readonly}
                ?required=${this.required}
                autocomplete=${this.autocomplete}
                ?min=${this.min}
                ?max=${this.max}
                step=${this.step}
                placeholder=${this.placeholder}
                .value="${this.value}"
                @input=${this.onInput}
            />
        `;
    }

    private onInput(event: InputEvent): void {
        const previousValue = this.value;
        this.value = (event.target as HTMLInputElement).value;
        this.requestUpdate('value', previousValue);
    }
}
