import { Vehicle } from './Model';

export default {
    toName,
    toSummary,
    toTaxationSummary,
};

export function toName(vehicle: {
    make: Vehicle['make'];
    model: Vehicle['model'];
    trimpackage: Vehicle['trimpackage'];
}): string {
    return [
        vehicle.make.humanvalue,
        vehicle.model.humanvalue,
        vehicle.trimpackage.humanvalue,
    ].join(' ');
}

export function toSummary(vehicle: {
    licenseplate: Vehicle['licenseplate'];
    mileage: Vehicle['mileage'];
    manufacture: Vehicle['manufacture'];
}): string {
    return [
        vehicle.licenseplate?.humanvalue,
        vehicle.mileage?.humanvalue,
        [
            vehicle.manufacture.month?.humanvalue,
            vehicle.manufacture.year?.humanvalue,
        ].join(' '),
    ]
        .filter((x) => x !== undefined)
        .join(' • ');
}

export function toTaxationSummary(vehicle: {
    licenseplate: Vehicle['licenseplate'];
    mileage: Vehicle['mileage'];
}): string {
    return [vehicle.licenseplate?.humanvalue, vehicle.mileage?.humanvalue]
        .filter((x) => x !== undefined)
        .join(' • ');
}
