import { Route } from '../../Route';
import { Html } from '../../Application';
import { html } from 'lit';
import Events from '../Events';

export type NavigationItems = (NavigationItem | NavigationItemWithSubItems)[];

type NavigationItem = {
    id: Route['id'];
    label: string;
    href: string;
    icon: Html;
};

type NavigationItemWithSubItems = {
    label: string;
    icon: Html;
    items: Omit<NavigationItem, 'icon'>[];
};

export function navigation(config: {
    activeRoute: Route;
    onNavigate: (href: string) => void;
    isExpanded: boolean;
    header: Html;
    items: (NavigationItem | NavigationItemWithSubItems)[];
}): Html {
    return html`
        <ata-navigation aria-expanded="${config.isExpanded ? 'true' : 'false'}">
            ${config.header}
            ${config.items.map(
                toNavigationItem.bind(
                    null,
                    config.activeRoute,
                    config.onNavigate,
                ),
            )}
        </ata-navigation>
    `;
}

function toNavigationItem(
    activeRoute: Route,
    onNavigate: (href: string) => void,
    item: NavigationItem | NavigationItemWithSubItems,
): Html {
    if ('items' in item) {
        return html`
            <ata-navigation-item-with-sub
                ?active="${isNavigationItemActive(item, activeRoute)}"
            >
                <span slot="icon">${item.icon}</span>
                <span slot="title" style="text-transform: uppercase">
                    ${item.label}
                </span>

                ${item.items.map(
                    (subItem) =>
                        html`<a
                            href="${subItem.href}"
                            @click="${Events.onLinkClick(onNavigate)}"
                        >
                            ${isNavigationItemActive(subItem, activeRoute)
                                ? html`<strong>${subItem.label}</strong>`
                                : html`<span>${subItem.label}</span>`}
                        </a>`,
                )}
            </ata-navigation-item-with-sub>
        `;
    }

    return html`
        <ata-navigation-item
            href="${item.href}"
            ?active="${isNavigationItemActive(item, activeRoute)}"
            @click="${(event: Event): void => {
                event.preventDefault();
                onNavigate(item.href);
            }}"
        >
            <span slot="icon">${item.icon}</span>
            <span slot="title" style="text-transform: uppercase;">
                ${item.label}
            </span>
        </ata-navigation-item>
    `;
}

function isNavigationItemActive(
    item: Omit<NavigationItem, 'icon'> | NavigationItemWithSubItems,
    currentRoute: Route,
): boolean {
    if ('items' in item) {
        return item.items.some((subItem) =>
            isNavigationItemActive(subItem, currentRoute),
        );
    }

    switch (currentRoute.id) {
        case 'SettingsCompetingCompaniesProfile': {
            return 'id' in item && item.id === 'SettingsCompetingCompanies';
        }

        case 'SettingsScenario': {
            return 'id' in item && item.id === 'SettingsScenarios';
        }

        default: {
            return item.id === currentRoute.id;
        }
    }
}
