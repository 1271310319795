export enum RequestMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Patch = 'PATCH',
    Delete = 'DELETE',
}

export type RequestHeaders = Record<string, string> | Headers;

export type Body = object;

export type Expect<output> = (
    response: Response,
) => PromiseLike<output> | CancelablePromise<output>;

export type HttpRequest<output> = {
    path: string;
    method: RequestMethod;
    headers: RequestHeaders;
    query: Record<string, string>;
    body?: Body;
    expect?: Expect<output>;
};

export type CancelablePromise<T> = Promise<T> & {
    cancel: () => void;
    cancelled: boolean;
    then: <result>(
        onfulfilled: (value: T) => result | PromiseLike<result>,
    ) => CancelablePromise<result>;
};

export function request<output>(
    request: HttpRequest<output>,
): CancelablePromise<output> {
    const abortController = new AbortController();
    const promise = fetch(createRequest(request), {
        signal: abortController.signal,
    }).then(request.expect) as CancelablePromise<output>;

    promise['cancelled'] = false;
    promise['cancel'] = function (): void {
        abortController.abort();
        this.cancelled = true;
    };

    return promise;
}

function createRequest<output>(config: HttpRequest<output>): Request {
    const url: string = toUrl(config.path, config.query ?? {});

    return new Request(url, {
        headers: config.headers,
        method: config.method,
        body:
            config.body === undefined ? undefined : JSON.stringify(config.body),
    });
}

function toUrl(path: string, query: Record<string, string>): string {
    const thePath = Array.isArray(path) ? path.join('/') : path;

    if (Object.keys(query)[0] === undefined) {
        return thePath;
    }

    return thePath + '?' + new URLSearchParams(query).toString();
}
