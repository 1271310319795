import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators';

declare global {
    interface HTMLElementTagNameMap {
        'ata-navigation-item': AtaNavigationItem;
    }
}

/**
 * @property {string} href - The URL to navigate to.
 * @slot icon
 * @slot title
 * @event navigate
 */
@customElement('ata-navigation-item')
export default class AtaNavigationItem extends LitElement {
    static styles = css`
        a {
            display: grid;
            grid-template-columns: 2rem 1fr;
            align-items: center;
            gap: 3rem;
            padding: 2rem;
            text-decoration: none;
            color: currentColor;
        }

        :host([active]) a {
            background-color: var(
                --ata--app--navigation--backgroundColor--active
            );
            color: var(--ata--app--navigation--foregroundColor--active);
            fill: var(--ata--app--navigation--foregroundColor--active);
        }
    `;

    @property({ type: String })
    href: string = '#';

    render(): TemplateResult {
        return html`
            <a href="${this.href}">
                <slot name="icon"></slot>
                <slot name="title">No text</slot>
            </a>
        `;
    }
}
