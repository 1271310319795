import { Html } from '../../Application';
import { html } from 'lit';

export function link(
    href: string,
    onClick: (href: string) => void,
    content: Html,
): Html {
    return html` <a
        href="${href}"
        @click=${(event: Event): void => {
            event.preventDefault();
            onClick(href);
        }}
    >
        ${content}
    </a>`;
}

export function externalLink(href: string, content: Html): Html {
    return html`<a href="${href}" target="_blank" rel="noopener noreferrer">
        ${content}
    </a>`;
}
