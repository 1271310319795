import { Html } from '../../Application';
import { css, html, nothing } from 'lit';
import { declareStyle } from '../Util';

declareStyle(css`
    ata-app-bar {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: 'start center end';
        align-items: center;
        justify-content: center;
        background: var(--color--primary);
        color: var(--ata--app--bar--foregroundColor);
        fill: var(--ata--app--bar--foregroundColor);
        block-size: var(--ata--app--bar--blockSize);
        position: sticky;
        top: 0;
        z-index: 1;
    }

    [slot='start'] {
        grid-area: start;
        justify-self: start;
    }

    [slot='center'] {
        grid-area: center;
        justify-self: center;
    }

    [slot='end'] {
        grid-area: end;
        justify-self: end;
    }
`);

export default function appBar(config: {
    start?: Html;
    center?: Html;
    end?: Html;
}): Html {
    const start = config.start ?? nothing;
    const center = config.center ?? nothing;
    const end = config.end ?? nothing;

    return html`
        <ata-app-bar>
            <div slot="start">${start}</div>
            <div slot="center">${center}</div>
            <div slot="end">${end}</div>
        </ata-app-bar>
    `;
}
