export function save<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
}

export function load<T>(key: string): T | undefined {
    const value = localStorage.getItem(key);

    if (value === null) {
        return undefined;
    }

    try {
        return JSON.parse(value);
    } catch {
        return undefined;
    }
}
