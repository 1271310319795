import { css, LitElement } from 'lit';

export class AtaInput extends LitElement {}

export const inputCss = css`
    :is(input, select, textarea, button) {
        inline-size: 100%;
        block-size: 100%;
        box-sizing: border-box;
        padding: 0 1rem;
        min-block-size: var(--ata--input--blockSize);
        border-radius: var(--ata--input--borderRadius);
        border: 1px solid var(--ata--input--borderColor);
        background: var(--ata--input--backgroundColor);
        color: var(--ata--input--foregroundColor);
        fill: var(--ata--input--foregroundColor);
        transition: outline-color var(--motion--duration--short) linear;
        outline: 1px solid transparent;
        font-family: inherit;
        font-size: inherit;
        text-overflow: ellipsis;
    }

    textarea {
        padding: 1rem;
    }

    :is(input, select, textarea, button):is(:hover, :focus) {
        outline: 1px solid var(--color--neutral-3);
    }

    :is(input, select, textarea, button):is(
            [readonly],
            [aria-readonly='true'],
            :disabled
        ) {
        cursor: default;
        outline: 1px solid transparent;
    }

    :is(input, select, textarea, button):disabled {
        cursor: not-allowed;
        color: #aaa;
        background-color: #eee;
    }

    :is(input, select, textarea, button):has(~ .arrow) {
        padding-inline-end: 3rem;
    }
`;

export const inputArrowCss = css`
    :host {
        position: relative;
    }

    .arrow {
        position: absolute;
        right: 1rem;
        block-size: 2rem;
        aspect-ratio: 1;
        top: 50%;
        translate: 0 -50%;
        pointer-events: none;
    }

    [popover]:popover-open ~ .arrow,
    .arrow:has(~ [popover]:popover-open) {
        rotate: -180deg;
    }

    :host(:is(:disabled, [readonly])) .arrow {
        opacity: 0;
    }
`;
