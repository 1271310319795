import { html } from 'lit';
import { horizontalLayout, verticalLayout } from '../Ui/Atoms/Layout';
import { bigHeading } from '../Ui/Templates/Heading';
import { strokedButton } from '../Ui/Templates/Button';
import { Html } from '../Application';
import { padding } from '../Ui/Templates/Padding';

export default function view(config: {
    onNavigate: (href: string) => void;
    onNavigateBack: () => void;
}): Html {
    return padding(
        'large',
        verticalLayout(['center', 'start'], 'large', [
            verticalLayout(['center', 'start'], 'small', [
                bigHeading(html`Pagina niet gevonden`),
                html`<p>
                    De door jou opgevraagde pagina bestaat niet (meer).
                </p>`,
            ]),
            horizontalLayout(['center', 'center'], 'medium', [
                strokedButton({
                    type: 'button',
                    content: html`Ga terug`,
                    theme: 'Primary',
                    onClick: config.onNavigateBack,
                }),
                html`of`,
                strokedButton({
                    content: html`Bekijk het dashboard`,
                    href: '/dashboard',
                    theme: 'Secondary',
                    onClick: config.onNavigate,
                }),
            ]),
        ]),
    );
}
