import { customElement, property } from 'lit/decorators';
import { css, html, LitElement, TemplateResult } from 'lit';

declare global {
    interface HTMLElementTagNameMap {
        'ata-vehicle-image': AtaVehicleImage;
    }
}

@customElement('ata-vehicle-image')
export default class AtaVehicleImage extends LitElement {
    static styles = css`
        :host {
            display: block;
            position: relative;
            aspect-ratio: 4 / 3;
            height: 100%;
        }

        img {
            display: block;
            max-block-size: 100%;
            max-inline-size: 100%;
        }

        ::slotted([slot='photoCount']:not(:empty)) {
            position: absolute;
            inset: auto 1rem 1rem auto;
            background: white;
            padding: 0.5rem;
            border-radius: 4px;
        }
    `;

    @property({ type: String })
    src: string = '';

    render(): TemplateResult {
        return html`
            <img src="${this.src}" />
            <slot name="photoCount"></slot>
        `;
    }
}
