import { MixinBase, MixinReturn } from './mixin';
import { LitElement } from 'lit';
import { property } from 'lit/decorators';

export interface WithDebouncedInput {
    debounceDuration: number;
}

/**
 * A mixin that adds debounced input functionality to a component.
 *
 * @attribute debounce-duration - The duration to wait before dispatching the debounced input event.
 * @event debouncedInput - Dispatched when the input event has not been dispatched for the debounce duration.
 */
export function mixinDebouncedInput<T extends MixinBase<LitElement>>(
    base: T,
): MixinReturn<T, WithDebouncedInput> {
    abstract class WithDebouncedInputElement
        extends base
        implements WithDebouncedInput
    {
        @property({ type: Number, attribute: 'debounce-duration' })
        debounceDuration = 0;

        #debounceTimeoutId = 0;

        connectedCallback(): void {
            super.connectedCallback();
            this.addEventListener('input', this.onInputForDebounce.bind(this));
        }

        disconnectedCallback(): void {
            this.removeEventListener(
                'input',
                this.onInputForDebounce.bind(this),
            );
            super.disconnectedCallback();
        }

        private onInputForDebounce(event: Event): void {
            if (!(event instanceof InputEvent)) {
                return;
            }

            clearTimeout(this.#debounceTimeoutId);
            this.#debounceTimeoutId = setTimeout(() => {
                this.dispatchEvent(new CustomEvent('debouncedInput', event));
            }, this.debounceDuration);
        }
    }

    return WithDebouncedInputElement;
}
