import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators';

declare global {
    interface HTMLElementTagNameMap {
        'ata-navigation-header': AtaNavigationHeader;
    }
}

/**
 * @slot icon
 * @slot title
 */
@customElement('ata-navigation-header')
export default class AtaNavigationHeader extends LitElement {
    static styles = css`
        :host {
            display: grid;
            grid-template-columns: 3rem 1fr;
            align-items: center;
            gap: 2rem;
            padding: 3rem 2rem;
            text-decoration: none;
            color: currentColor;
        }
    `;

    render(): TemplateResult {
        return html`
            <slot name="icon"></slot>
            <slot name="title">No text</slot>
        `;
    }
}
