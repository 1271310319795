import { Html } from '../../Application';
import { html } from 'lit';

export default function pageNavigation(
    {
        previous,
        next,
    }: {
        previous?: { href: string; label: string };
        next?: { href: string; label: string };
    },
    onNavigate: (href: string) => void,
): Html {
    return html`<ata-horizontal-layout align="fill center">
        ${previous === undefined
            ? html`<span></span>`
            : viewButton(() => onNavigate(previous.href), 'Vorige', previous)}
        ${next === undefined
            ? html`<span></span>`
            : viewButton(() => onNavigate(next.href), 'Volgende', next)}
    </ata-horizontal-layout>`;
}

function viewButton(
    onNavigate: () => void,
    prefix: string,
    config: { href: string; label: string },
): Html {
    return html` <ata-filled-button
        theme="primary"
        href="${config.href}"
        @click="${(event: Event): void => {
            event.preventDefault();
            onNavigate();
        }}"
    >
        ${prefix}: ${config.label}
    </ata-filled-button>`;
}
