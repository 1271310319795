import {
    CompetitionProfile,
    CompetitionProfileDealer,
    CompetitionProfileDraft,
} from '../Model';

export function create(): CompetitionProfileDraft {
    return {
        name: '',
        dealers: [],
    };
}

export function setName(
    profile: CompetitionProfile | CompetitionProfileDraft,
    name: string,
): CompetitionProfileDraft {
    const nextProfile: CompetitionProfileDraft = structuredClone(profile);

    nextProfile['name'] = name;

    return nextProfile;
}

export function addDealer(
    profile: CompetitionProfile | CompetitionProfileDraft,
    dealer: CompetitionProfileDealer,
): CompetitionProfileDraft {
    const nextProfile: CompetitionProfileDraft = structuredClone(profile);

    nextProfile['dealers'].push(dealer);

    return nextProfile;
}

export function removeDealer(
    profile: CompetitionProfile | CompetitionProfileDraft,
    dealer: CompetitionProfileDealer,
): CompetitionProfileDraft {
    const nextProfile: CompetitionProfileDraft = structuredClone(profile);

    nextProfile.dealers = nextProfile.dealers.filter(
        (theDealer) => theDealer.id === dealer.id,
    );

    return nextProfile;
}
