declare const process: {
    env: {
        API_DOMAIN: string;
        RECAPTCHA_SITEKEY: string;
        GOOGLE_MAPS_API_KEY: string;
        VERSION: string;
    };
};

export const apiDomain: string = process.env.API_DOMAIN;
export const captchaSiteKey: string = process.env.RECAPTCHA_SITEKEY;
export const googleMapsApiKey: string = process.env.GOOGLE_MAPS_API_KEY;
export const version: string = process.env.VERSION;
