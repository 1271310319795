import { customElement } from 'lit/decorators';
import { css } from 'lit';
import AtaTwoDimensionalLayout, {
    alignmentCss,
    gapCss,
    verticalAlignmentCss,
} from './AtaTwoDimensionalLayout';

declare global {
    interface HTMLElementTagNameMap {
        'ata-vertical-layout': AtaVerticalLayout;
    }
}

/**
 * @property align
 * @property gap
 * @slot @default
 */
@customElement('ata-vertical-layout')
export default class AtaVerticalLayout extends AtaTwoDimensionalLayout {
    static styles = [
        gapCss,
        alignmentCss,
        verticalAlignmentCss,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }
        `,
    ];
}
