import { html } from 'lit';
import { Html } from '../../Application';
import '../Layouts/AtaHorizontalLayout';
import '../Layouts/AtaVerticalLayout';

export function horizontalLayout(
    alignment: [inline: Alignment, block: Alignment],
    gap: Gap,
    items: Html[],
): Html {
    return html`<ata-horizontal-layout
        align="${alignment.join(' ')}"
        gap="${gap}"
    >
        ${items}
    </ata-horizontal-layout>`;
}

export function verticalLayout(
    alignment: [inline: Alignment, block: Alignment],
    gap: Gap,
    items: Html[],
): Html {
    return html`<ata-vertical-layout
        align="${alignment.join(' ')}"
        gap="${gap}"
    >
        ${items}
    </ata-vertical-layout>`;
}

export function fillContainer(
    content: Html,
    basisPercentage: number = 1,
): Html {
    return html`<div style="flex: 1 1 ${basisPercentage * 100}%;"
        >${content}</div
    >`;
}

export function noShrink(content: Html): Html {
    return html`<div style="flex-shrink: 0;">${content}</div>`;
}

type Alignment = 'start' | 'center' | 'end' | 'fill';

type Gap = 'none' | 'small' | 'medium' | 'large';
