export type Ok<value> = { type: 'Ok'; value: value };
export type Err<error> = { type: 'Err'; error: error };
export type Result<value, error> = Ok<value> | Err<error>;

export function ok<value>(value: value): Result<value, never> {
    return { type: 'Ok', value };
}

export function err<error>(error: error): Result<never, error> {
    return { type: 'Err', error };
}

export function isResult<value, error>(
    result: Result<value, error>,
): result is Result<value, error> {
    return isOk(result) || isErr(result);
}

export function isOk<value, error>(
    result: Result<value, error>,
): result is Ok<value> {
    return result.type === 'Ok';
}

export function isErr<value, error>(
    result: Result<value, error>,
): result is Err<error> {
    return result.type === 'Err';
}

export function match<value, error, output>(
    result: Result<value, error>,
    callback: { Ok: (value: value) => output; Err: (error: error) => output },
): output {
    switch (result.type) {
        case 'Ok':
            return callback.Ok(result.value);
        case 'Err':
            return callback.Err(result.error);
    }
}

export function withDefault<value, error>(
    result: Result<value, error>,
    defaultValue: value,
): value {
    return match(result, {
        Ok: (value) => value,
        Err: () => defaultValue,
    });
}

export function map<a, b, error>(
    result: Result<a, error>,
    fn: (value: a) => b,
): Result<b, error> {
    return match(result, {
        Ok: (value) => ok(fn(value)),
        Err: (error) => err(error) as Result<b, error>,
    });
}

type MappedValues<T, E> = T extends [Result<infer First, E>, ...infer Rest]
    ? [First, ...MappedValues<Rest, E>]
    : [];

export function mapN<T extends Array<Result<unknown, E>>, R, E>(
    results: [...T],
    fn: (...args: MappedValues<T, E>) => R,
): Result<R, E> {
    const args = [];

    for (const result of results) {
        if (isErr(result)) {
            return result;
        }
        args.push(result.value);
    }

    return ok(fn(...(args as MappedValues<T, E>)));
}

export function mapError<value, a, b>(
    result: Result<value, a>,
    fn: (error: a) => b,
): Result<value, b> {
    return match(result, {
        Ok: (value) => ok(value) as Result<value, b>,
        Err: (error) => err(fn(error)),
    });
}

export function andThen<a, b, x>(
    result: Result<a, x>,
    fn: (value: a) => Result<b, x>,
): Result<b, x> {
    return match(result, {
        Ok: fn,
        Err: (error) => err(error),
    });
}
