import { CSSResult } from 'lit';

/**
 * Declare a style in the global scope.
 *
 * **IMPORTANT**: Every component should be wrapped in a custom tag or a unique class to avoid conflicts
 * as the styles are declared in the global scope.
 *
 * This function is intended to be used only for UI components.
 *
 * @example
 * ```ts
 * import { css } from 'lit';
 *
 * declareStyle(css`
 *    ata-checkbox {
 *       // Your styles here
 *    }
 * `);
 *
 * export default function checkbox() {
 *    return html`
 *      <ata-checkbox>
 *          // Your implementation here
 *      </ata-checkbox>
 *    `;
 * }
 * ```
 */
export function declareStyle(value: CSSResult): void {
    const styleEl = document.createElement('style');
    styleEl.innerHTML = value.cssText;
    document.head.append(styleEl);
}
