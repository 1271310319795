import { Html } from '../../Application';
import { html, nothing } from 'lit';
import Icon from '../Atoms/Icon';
import '../Molecules/AtaNavigationHeader';
import '../Molecules/AtaNavigationItem';
import '../Molecules/AtaNavigationItemWithSub';
import { Account } from '../../Model';
import { link } from './Link';
import { Route } from '../../Route';
import { navigation, NavigationItems } from './Navigation';

export default function mainNavigation(
    account: Account,
    activeRoute: Route,
    isExpanded: boolean,
    onNavigate: (href: string) => void,
): Html {
    return navigation({
        activeRoute,
        onNavigate,
        isExpanded,
        header: html` <ata-navigation-header>
            <span slot="icon">${Icon.dealer()}</span>
            <ata-vertical-layout slot="title">
                <span>${account.name}</span>
                ${account.type !== 'Dealer'
                    ? link(
                          '/instellingen/vestigingen',
                          onNavigate,
                          html`Rapportage aanpassen`,
                      )
                    : nothing}
            </ata-vertical-layout>
        </ata-navigation-header>`,
        items: navigationItems(account),
    });
}

function navigationItems(account: Account): NavigationItems {
    return (
        [
            {
                id: 'Dashboard',
                label: 'Dashboard',
                href: '/dashboard',
                icon: Icon.dashboard(),
            },
            {
                id: 'Stock',
                label: 'Voorraad',
                href: '/voorraad',
                icon: Icon.voorraad(),
            },
            {
                id: 'Reach',
                label: 'Bereik',
                href: '/bereik',
                icon: Icon.bereik(),
            },
            {
                id: 'Leads',
                label: 'Leads',
                href: '/leads',
                icon: Icon.leads(),
            },
            account.isMaster && {
                id: 'Prospects',
                label: 'Prospects',
                href: '/prospects',
                icon: Icon.prospects(),
            },
            {
                label: 'Analyse',
                icon: Icon.analyse(),
                items: [
                    {
                        id: 'AnalysisOverview',
                        label: 'Overzicht',
                        href: '/analyse/overzicht',
                    },
                    {
                        id: 'AnalysisLeadPerformance',
                        label: 'Leads prestatie',
                        href: '/analyse/leadsprestatie',
                    },
                    {
                        id: 'AnalysisMakeModel',
                        label: 'Merk/model',
                        href: '/analyse/merkmodel',
                    },
                    {
                        id: 'AnalysisCostBenefit',
                        label: 'Kosten en baten',
                        href: '/analyse/kostenbaten',
                    },
                ],
            },
            {
                label: 'Bedrijven Vergelijken',
                icon: Icon.vergelijken(),
                items: [
                    {
                        id: 'ComparisonAdvicePrice',
                        label: 'Adviesprijs',
                        href: '/vergelijk/adviesprijs',
                    },
                    {
                        id: 'ComparisonDaysInStock',
                        label: 'Statijd',
                        href: '/vergelijk/statijd',
                    },
                    {
                        id: 'ComparisonGeographic',
                        label: 'Geografisch',
                        href: '/vergelijk/geografisch',
                    },
                ],
            },
            {
                label: 'Instellingen',
                icon: Icon.settings(),
                items: [
                    account.type !== 'Dealer' && {
                        id: 'SettingsScenarios',
                        label: 'Clusters aanpassen',
                        href: '/instellingen/scenarios',
                    },
                    account.type !== 'Dealer' && {
                        id: 'SettingsBranches',
                        label: 'Rapportage aanpassen',
                        href: '/instellingen/vestigingen',
                    },
                    {
                        id: 'SettingsStock',
                        label: 'Voorraad',
                        href: '/instellingen/voorraad',
                    },
                    {
                        id: 'SettingsCompetingVehicles',
                        label: "Vergelijkbare auto's",
                        href: '/instellingen/concurrentievoertuigen',
                    },
                    {
                        id: 'SettingsCompetingCompanies',
                        label: 'Profielen vergelijkbare bedrijven',
                        href: '/instellingen/concurrentiebedrijven',
                    },
                    {
                        id: 'SettingsNotifications',
                        label: 'Doel en meldingen',
                        href: '/instellingen/doelenmeldingen',
                    },
                ].filter(Boolean),
            },
            {
                id: 'NotFound',
                label: 'Uitloggen',
                href: '/logout',
                icon: Icon.logout(),
            },
        ] as NavigationItems
    ).filter(Boolean);
}
