import { Html } from '../../Application';
import AtaTextInput from '../Atoms/AtaTextInput';
import '../Atoms/AtaTextInput';
import { css, html } from 'lit';
import { declareStyle } from '../Util';

declareStyle(css`
    ata-license-plate-input {
        display: flex;
        flex-direction: row;
        --ata--input--backgroundColor: #f7c033;
        --ata--input--borderColor: #f7c033;
    }

    ata-license-plate-input > div {
        background: #0028d8;
        display: flex;
        align-items: center;
        justify-content: center;
        border-start-start-radius: var(--ata--input--borderRadius);
        border-end-start-radius: var(--ata--input--borderRadius);
    }

    ata-license-plate-input ::part(input) {
        text-transform: uppercase;
        border-start-start-radius: 0;
        border-end-start-radius: 0;
    }
`);

export default function licensePlateInput(config: {
    name: string;
    value: string;
    placeholder?: string;
    autocomplete?: string;
    errorMessage?: string;
    isDisabled?: boolean;
    onChange?: (value: string) => void;
    debounce?: {
        duration: number;
        onChange: (value: string) => void;
    };
}): Html {
    const isDisabled = config.isDisabled === true;
    const isReadonly =
        config.onChange === undefined &&
        config.debounce === undefined &&
        !isDisabled;

    const onChange =
        config.onChange !== undefined
            ? (event: Event): void => {
                  config.onChange!((event.target as AtaTextInput).value);
              }
            : (): void => {};

    const onDebouncedChange =
        config.debounce !== undefined
            ? (event: CustomEvent): void => {
                  config.debounce!.onChange(
                      (event.target as AtaTextInput).value,
                  );
              }
            : (): void => {};

    return html` <ata-license-plate-input>
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
            >
                <g>
                    <path
                        fill="#FFEB33"
                        d="M10 0L9.81 0.6 9.17 0.6 9.69 0.96 9.5 1.54 10 1.19 10.5 1.54 10.31 0.96 10.83 0.6 10.19 0.6z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M12.59 0.68L12.39 1.27 11.76 1.27 12.28 1.64 12.09 2.22 12.59 1.86 13.08 2.22 12.9 1.64 13.41 1.27 12.78 1.27z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M6.92 2.22L7.41 1.86 7.91 2.22 7.72 1.64 8.24 1.27 7.61 1.27 7.41 0.68 7.22 1.27 6.59 1.27 7.1 1.64z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M13.99 4.12L14.49 3.76 14.99 4.12 14.8 3.54 15.32 3.17 14.68 3.17 14.49 2.57 14.3 3.17 13.66 3.17 14.18 3.54z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M5.51 2.57L5.32 3.17 4.68 3.17 5.2 3.54 5.01 4.12 5.51 3.76 6.01 4.12 5.82 3.54 6.34 3.17 5.7 3.17z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M16 5.77L15.37 5.77 15.17 5.17 14.98 5.77 14.35 5.77 14.86 6.13 14.68 6.71 15.17 6.36 15.67 6.71 15.48 6.13z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M4.83 6.36L5.32 6.71 5.14 6.13 5.65 5.77 5.02 5.77 4.83 5.17 4.63 5.77 4 5.77 4.52 6.13 4.33 6.71z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M14.68 8.35L14.49 7.76 14.3 8.35 13.66 8.35 14.18 8.72 13.99 9.3 14.49 8.94 14.99 9.3 14.8 8.72 15.32 8.35z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M5.7 8.35L5.51 7.76 5.32 8.35 4.68 8.35 5.2 8.72 5.01 9.3 5.51 8.94 6.01 9.3 5.82 8.72 6.34 8.35z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M12.78 10.25L12.59 9.65 12.39 10.25 11.76 10.25 12.28 10.62 12.09 11.19 12.59 10.84 13.08 11.19 12.9 10.62 13.41 10.25z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M7.61 10.25L7.41 9.65 7.22 10.25 6.59 10.25 7.1 10.62 6.92 11.19 7.41 10.84 7.91 11.19 7.72 10.62 8.24 10.25z"
                    />
                    <path
                        fill="#FFEB33"
                        d="M10.19 10.95L10 10.35 9.81 10.95 9.17 10.95 9.69 11.32 9.5 11.9 10 11.54 10.5 11.9 10.31 11.32 10.83 10.95z"
                    />
                </g>
                <g>
                    <path
                        d="M4.93,20v-6.04h1.19l2.47,4.04v-4.04h1.13V20H8.5l-2.44-3.94V20H4.93z"
                    />
                    <path d="M11.04,20v-5.99h1.22v4.98h3.03V20H11.04z" />
                </g>
            </svg>
        </div>
        <ata-text-input
            name=${config.name}
            value=${config.value}
            placeholder=${config.placeholder}
            autocomplete=${config.autocomplete ?? 'off'}
            error-message=${config.errorMessage}
            debounce-duration=${config.debounce?.duration}
            ?disabled=${isDisabled}
            ?readonly=${isReadonly}
            @input=${onChange}
            @debouncedInput=${onDebouncedChange}
        ></ata-text-input>
    </ata-license-plate-input>`;
}
