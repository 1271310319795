import { css, html, LitElement, TemplateResult } from 'lit';
import { property } from 'lit/decorators';

export const ataButtonStyles = css`
    :is(button, a) {
        ---ataButton--backgroundColor: transparent;
        ---ataButton--foregroundColor: currentColor;
        ---ataButton--borderColor: transparent;
        position: relative;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: var(--ata--button--borderRadius);
        padding-inline: 1.5rem;
        gap: 0.5rem;
        block-size: var(--ata--button--blockSize);
        border: none;
        outline: 1px solid var(---ataButton--borderColor);
        outline-offset: -1px;
        font-size: 0.875em;
        font-weight: 700;
        line-height: calc(var(--ata--button--blockSize) / 2);
        text-decoration: none;
        cursor: pointer;
        color: var(---ataButton--foregroundColor);
        fill: var(---ataButton--foregroundColor);
        background: var(---ataButton--backgroundColor);
        inline-size: 100%;
        box-sizing: border-box;

        &:disabled {
            cursor: not-allowed;
        }
    }

    :host-context([dense]) :is(a, button) {
        block-size: var(--ata--button--blockSize--dense);
        line-height: calc(var(--ata--button--blockSize--dense) / 2);
    }

    :is(a, button)[aria-busy='true'] {
        &[disabled] {
            cursor: wait;
        }

        &::after {
            content: '';
            display: inline-block;
            margin-left: 0.5rem;
            aspect-ratio: 1;
            height: 1rem;
            width: 1rem;
            position: relative;
            animation: rotation 1s infinite linear;
            border: 0.25rem solid transparent;
            border-top-color: var(---ataButton--foregroundColor);
            border-left-color: var(---ataButton--foregroundColor);
            border-radius: 100%;
            vertical-align: middle;
        }
    }

    @keyframes rotation {
        to {
            transform: rotate(359deg);
        }
    }

    ::slotted(ata-icon) {
        height: 50%;
    }
`;

export default abstract class AtaButton extends LitElement {
    static formAssociated = true;

    static shadowRootOptions = {
        ...LitElement.shadowRootOptions,
        delegatesFocus: true,
    };

    #internals: ElementInternals = this.attachInternals();

    @property({ type: Boolean, reflect: true })
    disabled = false;

    @property({ type: Boolean, reflect: true })
    progress = false;

    @property({ type: String })
    href: string | undefined = undefined;

    @property({
        type: String,
        converter: {
            fromAttribute(value: string) {
                switch (value.toLowerCase()) {
                    case 'submit':
                        return 'submit';
                    case 'reset':
                        return 'reset';
                    default:
                        return 'button';
                }
            },
        },
    })
    type: 'submit' | 'reset' | 'button' = 'button';

    attributeChangedCallback(
        name: string,
        _old: string | null,
        value: string | null,
    ): void {
        switch (name) {
            case 'type':
            case 'disabled':
            case 'href':
            case 'progress': {
                this.requestUpdate(name, _old);
            }
        }

        super.attributeChangedCallback(name, _old, value);
    }

    render(): TemplateResult {
        return this.href === undefined || this.disabled
            ? this.renderButton()
            : this.renderLink();
    }

    renderButton(): TemplateResult {
        return html`
            <button
                ?disabled="${this.disabled}"
                aria-busy="${this.progress ? 'true' : 'false'}"
                @click=${this.onClick}
            >
                <slot></slot>
            </button>
        `;
    }

    renderLink(): TemplateResult {
        return html`
            <a href=${this.href} aria-busy="${this.progress}">
                <slot></slot>
            </a>
        `;
    }

    protected onClick(): void {
        switch (this.type) {
            case 'submit': {
                this.#internals.form?.requestSubmit(null);
                return;
            }

            case 'reset': {
                this.#internals.form?.reset();
                return;
            }
        }
    }
}
