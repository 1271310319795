type Just<T> = { type: 'Just'; value: T };

type Nothing = { type: 'Nothing' };

export type Maybe<T> = Just<T> | Nothing;

export function just<T>(value: T): Maybe<T> {
    return { type: 'Just', value };
}

export function nothing<T>(): Maybe<T> {
    return { type: 'Nothing' };
}

export function isJust<T>(maybe: Maybe<T>): maybe is Just<T> {
    return maybe.type === 'Just';
}

export function isNothing<T>(maybe: Maybe<T>): maybe is Nothing {
    return maybe.type === 'Nothing';
}

export function withDefault<T>(maybe: Maybe<T>, defaultValue: T): T {
    return isJust(maybe) ? maybe.value : defaultValue;
}

export function map<T, U>(maybe: Maybe<T>, fn: (value: T) => U): Maybe<U> {
    return isJust(maybe) ? just(fn(maybe.value)) : nothing();
}

type MappedValues<T> = T extends [Maybe<infer First>, ...infer Rest]
    ? [First, ...MappedValues<Rest>]
    : [];

export function mapN<T extends Array<Maybe<unknown>>, R>(
    results: [...T],
    fn: (...args: MappedValues<T>) => R,
): Maybe<R> {
    const args = [];

    for (const result of results) {
        if (isNothing(result)) {
            return result;
        }
        args.push(result.value);
    }

    return just(fn(...(args as MappedValues<T>)));
}

export function andThen<T, U>(
    maybe: Maybe<T>,
    fn: (value: T) => Maybe<U>,
): Maybe<U> {
    return match(maybe, {
        Just: fn,
        Nothing: () => nothing(),
    });
}

export function match<T, U>(
    maybe: Maybe<T>,
    callbacks: {
        Just: (value: T) => U;
        Nothing: () => U;
    },
): U {
    switch (maybe.type) {
        case 'Just':
            return callbacks.Just(maybe.value);
        case 'Nothing':
            return callbacks.Nothing();
    }
}

export function fromNullable<T>(value: T | null | undefined): Maybe<T> {
    return value === null || value === undefined ? nothing() : just(value);
}

export function fromUndefined<T>(value: T | undefined): Maybe<T> {
    return value === undefined ? nothing() : just(value);
}
