import {
    ComplexAttributeConverter,
    css,
    html,
    LitElement,
    PropertyValues,
} from 'lit';
import { property } from 'lit/decorators';
import { Html } from '../../Application';
import isEqual from 'fast-deep-equal';

type Alignment = 'start' | 'center' | 'end' | 'fill';

type Gap = 'none' | 'small' | 'medium' | 'large';

export const gapCss = css`
    :host([gap='none']) {
        gap: 0;
    }

    :host([gap='small']) {
        gap: var(--dimensions--spacing--small);
    }

    :host([gap='medium']) {
        gap: var(--dimensions--spacing--medium);
    }

    :host([gap='large']) {
        gap: var(--dimensions--spacing--large);
    }
`;

export const alignmentCss = css`
    :host([align='start']) {
        justify-content: flex-start;
        align-items: flex-start;
    }

    :host([align='center']) {
        justify-content: center;
        align-items: center;
    }

    :host([align='end']) {
        justify-content: flex-end;
        align-items: flex-end;
    }

    :host([align='fill']) {
        justify-content: space-between;
        align-items: stretch;
    }
`;

export const horizontalAlignmentCss = css`
    :host([align^='start ']) {
        justify-content: flex-start;
    }

    :host([align^='center ']) {
        justify-content: center;
    }

    :host([align^='end ']) {
        justify-content: flex-end;
    }

    :host([align^='fill ']) {
        justify-content: space-between;
    }

    :host([align$=' start']) {
        align-items: flex-start;
    }

    :host([align$=' center']) {
        align-items: center;
    }

    :host([align$=' end']) {
        align-items: flex-end;
    }

    :host([align$=' fill']) {
        align-items: stretch;
    }
`;

export const verticalAlignmentCss = css`
    :host([align$=' start']) {
        justify-content: flex-start;
    }

    :host([align$=' center']) {
        justify-content: center;
    }

    :host([align$=' end']) {
        justify-content: flex-end;
    }

    :host([align$=' fill']) {
        justify-content: space-between;
    }

    :host([align^='start ']) {
        align-items: flex-start;
    }

    :host([align^='center ']) {
        align-items: center;
    }

    :host([align^='end ']) {
        align-items: flex-end;
    }

    :host([align^='fill ']) {
        align-items: stretch;
    }
`;

export default abstract class AtaTwoDimensionalLayout extends LitElement {
    @property({
        converter: alignAttributeConverter(),
        hasChanged: isEqual,
        reflect: true,
    })
    align: [Alignment, Alignment] = ['start', 'start'];

    @property({
        reflect: true,
        converter: gapAttributeConverter(),
    })
    gap: Gap = 'none';

    render(): Html {
        return html`<slot></slot>`;
    }

    connectedCallback(): void {
        // this.updateAlignStyles();
        super.connectedCallback();
    }

    protected updated(_changedProperties: PropertyValues): void {
        if (_changedProperties.has('align')) {
            // this.updateAlignStyles();
        }

        super.updated(_changedProperties);
    }
}

function alignAttributeConverter(): ComplexAttributeConverter {
    return {
        fromAttribute(value: string | null): [Alignment, Alignment] {
            let [x, y] = value?.split(' ') ?? ['start', 'start'];

            if (!isAlignment(x)) {
                x = 'start';
            }

            if (y === undefined) {
                y = String(x);
            }

            if (!isAlignment(y)) {
                y = 'start';
            }

            return [x, y] as [Alignment, Alignment];
        },
        toAttribute(value: [Alignment, Alignment]): string {
            return value.join(' ');
        },
    };
}

function gapAttributeConverter(): ComplexAttributeConverter {
    return {
        fromAttribute(value: string | null): unknown {
            if (value !== null && isGap(value)) {
                return value;
            }

            return 'none';
        },
    };
}

function isAlignment(value: string): value is Alignment {
    return ['start', 'center', 'end', 'fill'].includes(value);
}

function isGap(value: string): value is Gap {
    return ['none', 'small', 'medium', 'large'].includes(value);
}
