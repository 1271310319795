import { Html } from '../../Application';
import { html } from 'lit';
import '../Layouts/AtaLayoutShell';
import { DeviceLayout } from '../../Model';
import appBar from './AppBar';
import { paddingBlockInline } from './Padding';
import appFooter from './AppFooter';
import { version } from '../../Environment';

export default function shell(deviceLayout: DeviceLayout, content: Html): Html {
    return html`
        <div slot="header"> ${viewAppBar(deviceLayout)} </div>

        ${content}

        <hr />

        ${appFooter()}
    `;
}

function viewAppBar(deviceLayout: DeviceLayout): Html {
    switch (deviceLayout) {
        case 'Desktop': {
            return appBar({
                start: paddingBlockInline(
                    { block: 'none', inline: 'medium' },
                    html`<img
                        loading="lazy"
                        src="https://analytics.autotrack.nl/assets/images/autotrack-analytics.png"
                        style="block-size: 3rem"
                        title="AutoTrack Analytics v${version}"
                    />`,
                ),
            });
        }

        case 'Mobile': {
            return appBar({
                center: paddingBlockInline(
                    { block: 'none', inline: 'medium' },
                    html`<img
                        loading="lazy"
                        src="https://analytics.autotrack.nl/assets/images/autotrack-analytics.png"
                        style="block-size: 3rem"
                        title="AutoTrack Analytics v${version}"
                    />`,
                ),
            });
        }
    }
}
