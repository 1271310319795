type HashFunction<T> = (item: T) => string;

export type Changes<T> = {
    additions: Map<string, T>;
    deletions: Map<string, T>;
};

export function init<T>(): Changes<T> {
    return {
        additions: new Map(),
        deletions: new Map(),
    };
}

export function hasChanges(changes: Changes<unknown>): boolean {
    return changes.additions.size > 0 || changes.deletions.size > 0;
}

export function add<T>(
    changes: Changes<T>,
    toHash: HashFunction<T>,
    item: T,
): Changes<T> {
    const nextChanges = structuredClone(changes);

    const hash = toHash(item);
    nextChanges.additions.set(hash, item);
    nextChanges.deletions.delete(hash);

    return nextChanges;
}

export function remove<T>(
    changes: Changes<T>,
    toHash: HashFunction<T>,
    item: T,
): Changes<T> {
    const nextChanges = structuredClone(changes);

    const hash = toHash(item);
    nextChanges.deletions.set(hash, item);
    nextChanges.additions.delete(hash);

    return nextChanges;
}

export function applyTo<T>(
    list: T[],
    toHash: HashFunction<T>,
    changes: Changes<T>,
): T[] {
    const listHashes = list.map(toHash);
    const deletionHashes = Array.from(changes.deletions.keys());

    const nextList = list.filter(
        (item) => !deletionHashes.includes(toHash(item)),
    );

    changes.additions.forEach((item) => {
        if (!listHashes.includes(toHash(item))) {
            nextList.push(item);
        }
    });

    return nextList;
}
