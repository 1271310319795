import { html, nothing } from 'lit';
import { Account, DeviceLayout, Vehicle } from '../../Model';
import '../Layouts/AtaLayoutShell';
import '../Organisms/AtaNavigation';
import '../Organisms/AtaNavigationGroup';
import '../Organisms/AtaPageHeader';
import '../Organisms/AtaVehiclePageHeader';
import '../Layouts/AtaHorizontalLayout';
import '../Layouts/AtaVerticalLayout';
import '../Atoms/AtaBigHeading';
import '../Atoms/AtaMediumHeading';
import appBar from './AppBar';
import { padding, paddingBlockInline, paddingEach } from './Padding';
import { button } from './Button';
import Icon from '../Atoms/Icon';
import { Html } from '../../Application';
import * as VehicleUtil from '../../Vehicle';
import pageNavigation from './PageNavigation';
import taxationNavigation from './TaxationNavigation';
import mainNavigation from './MainNavigation';
import taxationWidget from './TaxationWidget';
import appFooter from './AppFooter';
import { Route } from '../../Route';
import { version } from '../../Environment';

export default function taxationShell(config: {
    deviceLayout: DeviceLayout;
    pageTitle: string;
    pageActions: Html[];
    pageNavigations?: {
        previous?: { href: string; label: string };
        next?: { href: string; label: string };
    };
    content: Html;
    account: Account;
    vehicle: Vehicle;
    activeRoute: Route;
    isNavigationVisible: boolean;
    onNavigate: (href: string) => void;
    onToggleNavigation: () => void;
    taxationWidget?: {
        licensePlate: string;
        mileage: string;
        errorMessage: string | null;
        onLicensePlateChange: (value: string) => void;
        onMileageChange: (value: string) => void;
        onSubmit: () => void;
        onClearErrorMessage: () => void;
    };
}): Html {
    return html`
        <ata-layout-shell ?navigation-visible="${config.isNavigationVisible}">
            <div slot="header">
                ${viewAppBar(config.deviceLayout, config.onToggleNavigation)}
            </div>

            <div
                slot="navigation"
                style="block-size: 100%; background: var(--ata--app--navigation--backgroundColor); display: flex; flex-direction: column; overflow: hidden;"
            >
                <ata-navigation-group style="flex: 1; overflow: hidden;">
                    ${mainNavigation(
                        config.account,
                        config.activeRoute,
                        false,
                        config.onNavigate,
                    )}
                    ${taxationNavigation(
                        true,
                        config.activeRoute,
                        config.vehicle.id.value,
                        config.vehicle.licenseplate.humanvalue,
                        config.onNavigate,
                    )}
                </ata-navigation-group>

                ${config.taxationWidget !== undefined
                    ? html`
                          <div
                              style="flex: 0; position: sticky; inset: auto 0 0 0; padding: var(--dimensions--spacing--medium); box-sizing: border-box;"
                          >
                              ${taxationWidget(config.taxationWidget)}
                          </div>
                      `
                    : nothing}
            </div>

            ${vehiclePageHeader({
                title: html`${config.pageTitle}`,
                pageActions: config.pageActions,
                vehicle: config.vehicle,
            })}

            <main style="position: relative"> ${config.content} </main>

            ${config.pageNavigations
                ? paddingEach(
                      {
                          blockStart: 'large',
                          blockEnd: 'none',
                          inlineStart: 'none',
                          inlineEnd: 'none',
                      },
                      html`<hr />`,
                  )
                : nothing}
            ${padding(
                'large',
                pageNavigation(
                    {
                        previous: config.pageNavigations?.previous,
                        next: config.pageNavigations?.next,
                    },
                    config.onNavigate,
                ),
            )}
            ${appFooter(config.account.lastUpdate)}
        </ata-layout-shell>
    `;
}

function viewAppBar(
    deviceLayout: DeviceLayout,
    onToggleNavigation: () => void,
): Html {
    switch (deviceLayout) {
        case 'Desktop': {
            return appBar({
                start: paddingBlockInline(
                    { block: 'none', inline: 'medium' },
                    html`<img
                        loading="lazy"
                        src="https://analytics.autotrack.nl/assets/images/autotrack-analytics.png"
                        style="block-size: 3rem"
                        title="AutoTrack Analytics v${version}"
                    />`,
                ),
            });
        }

        case 'Mobile': {
            return appBar({
                center: paddingBlockInline(
                    { block: 'none', inline: 'medium' },
                    html`<img
                        loading="lazy"
                        src="https://analytics.autotrack.nl/assets/images/autotrack-analytics.png"
                        style="block-size: 3rem"
                        title="AutoTrack Analytics v${version}"
                    />`,
                ),
                end: button({
                    theme: 'Secondary',
                    content: Icon.hamburger(),
                    onClick: onToggleNavigation,
                }),
            });
        }
    }
}

function vehiclePageHeader(config: {
    title: Html;
    pageActions: Html[];
    vehicle: Vehicle;
}): Html {
    const vehicleName = VehicleUtil.toName(config.vehicle);
    const vehicleSummary = VehicleUtil.toTaxationSummary(config.vehicle);

    return html`
        <ata-vehicle-page-header>
            <ata-vertical-layout align="fill" gap="medium">
                <ata-big-heading>Taxatie: ${vehicleName}</ata-big-heading>
                <p> ${vehicleSummary} </p>
            </ata-vertical-layout>
        </ata-vehicle-page-header>

        <ata-page-header>
            <ata-big-heading slot="title">${config.title}</ata-big-heading>

            <ata-horizontal-layout gap="small" slot="actions">
                ${config.pageActions}
            </ata-horizontal-layout>
        </ata-page-header>
    `;
}
