import { Html } from '../../Application';
import { css, html } from 'lit';
import { declareStyle } from '../Util';
import { verticalLayout } from '../Atoms/Layout';
import { bigHeading } from './Heading';

declareStyle(css`
    ata-premium-peak {
        display: block;
        max-block-size: 500px;
        overflow: hidden;
        position: relative;

        [content] {
            pointer-events: none;
        }

        [explanation] {
            position: absolute;
            inset: 0;
            background: linear-gradient(
                to top,
                rgba(256, 256, 256, 1) 30%,
                rgba(256, 256, 256, 0) 60%
            );
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;
            padding-block-end: 2.5%;
        }
    }
`);

export function premiumPeak(title: string, content: Html): Html {
    return html`
        <ata-premium-peak>
            <div content>${content}</div>
            <div explanation>
                ${verticalLayout(['center', 'start'], 'small', [
                    bigHeading(html`${title}`),
                    html`<p>
                        Upgrade naar een premium account, bel
                        <a href="tel:0202042720">
                            020&nbsp;204&nbsp;27&nbsp;20
                        </a>
                    </p>`,
                ])}
            </div>
        </ata-premium-peak>
    `;
}
