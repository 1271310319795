export type ApplicationError =
    | { type: 'UnexpectedError'; originalError?: unknown }
    | { type: 'UnexpectedBody'; originalError?: unknown }
    | { type: 'CaptchaRequired' }
    | { type: 'LoginWithCredentialsFailed' }
    | { type: 'TaxationNotFound' };

export const unexpectedError = (originalError?: unknown): ApplicationError => ({
    type: 'UnexpectedError',
    originalError,
});

export const unexpectedBody = (originalError?: unknown): ApplicationError => ({
    type: 'UnexpectedBody',
    originalError,
});

export const loginWithCredentialsFailed = (): ApplicationError => ({
    type: 'LoginWithCredentialsFailed',
});

export const captchaRequired = (): ApplicationError => ({
    type: 'CaptchaRequired',
});

export const taxationNotFound = (): ApplicationError => ({
    type: 'TaxationNotFound',
});
