import { Html } from '../../Application';
import { intlText } from './IntlText';
import { html } from 'lit';

export default {
    number,
    currency,
    percentage,
    date,
    datetime,
    relativeTime,
};

export function number(
    value: number,
    fractions: number = 0,
    sign: 'auto' | 'always' | 'except-zero' = 'auto',
): Html {
    return toHtml(
        `{value, number, ::${toFractionsOption(fractions)} sign-${sign}}`,
        value,
    );
}

export function currency(
    value: number,
    fractions: number = 0,
    sign: 'auto' | 'always' | 'except-zero' = 'auto',
): Html {
    return toHtml(
        `{value, number, ::${toFractionsOption(fractions)} sign-${sign} currency/EUR}`,
        value,
    );
}

export function percentage(
    value: number,
    fractions: number = 0,
    sign: 'auto' | 'always' | 'except-zero' = 'auto',
): Html {
    return toHtml(
        `{value, number, ::${toFractionsOption(fractions)} sign-${sign} percent}`,
        value,
    );
}

export function date(value: Date): Html {
    return toHtml('{value, date, ::yyyyMMdd}', value.valueOf());
}

export function datetime(value: Date): Html {
    return toHtml('{value, date, ::yyyyMMddHHmm}', value.valueOf());
}

export function relativeTime(
    unit: Intl.RelativeTimeFormatUnit,
    value: number,
): Html {
    const text = new Intl.RelativeTimeFormat('nl-NL', {
        style: 'long',
    }).format(value, unit);

    return html`${text}`;
}

function toFractionsOption(amount: number): string {
    if (amount === 0) {
        return '.';
    }

    const zeros = Array.from({ length: amount })
        .map(() => '0')
        .join('');

    return `.${zeros}`;
}

function toHtml(string: string, value: unknown): Html {
    return intlText(string, { value });
}
