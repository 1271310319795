import { customElement } from 'lit/decorators';
import { css, html, LitElement, TemplateResult } from 'lit';

declare global {
    interface HTMLElementTagNameMap {
        'ata-vehicle-page-header': AtaVehiclePageHeader;
    }
}

@customElement('ata-vehicle-page-header')
export default class AtaVehiclePageHeader extends LitElement {
    static styles = css`
        :host {
            display: grid;
            grid-template-columns: auto 1fr;
            background: #ddd;
        }

        .pageHeader__vehicleImage:has(:not(slot:empty)) {
            block-size: 18rem;
        }

        .pageHeader__vehicleDetails {
            padding: 2rem;
        }
    `;

    render(): TemplateResult {
        return html`
            <div class="pageHeader__vehicleImage">
                <slot name="image"></slot>
            </div>

            <div class="pageHeader__vehicleDetails">
                <slot></slot>
            </div>
        `;
    }
}
