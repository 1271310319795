import { SettingsStock, SettingsStockForm } from '../Model';

export function toForm(
    original: SettingsStock,
    changes: Partial<SettingsStockForm>,
): SettingsStockForm {
    return {
        ...fromOriginal(original),
        ...changes,
    };
}

export function fromForm(form: SettingsStockForm): SettingsStock {
    return {
        excludedvehicleids: Array.from(form.excludedvehicleids.values()),
        mileagemax: form.mileagemax === '' ? null : Number(form.mileagemax),
        mileagemin: form.mileagemin === '' ? null : Number(form.mileagemin),
        reachfiltered: form.reachfiltered,
        vehicleimported: form.vehicleimported,
        vehiclenew: form.vehiclenew,
        vehiclenotimported: form.vehiclenotimported,
    };
}

function fromOriginal(original: SettingsStock): SettingsStockForm {
    return {
        excludedvehicleids: new Set(original.excludedvehicleids),
        mileagemax: original.mileagemax ? String(original.mileagemax) : '',
        mileagemin: original.mileagemin ? String(original.mileagemin) : '',
        reachfiltered: original.reachfiltered,
        vehicleimported: original.vehicleimported,
        vehiclenew: original.vehiclenew,
        vehiclenotimported: original.vehiclenotimported,
    };
}
