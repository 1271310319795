import { customElement } from 'lit/decorators';
import { css, html, LitElement, TemplateResult } from 'lit';

declare global {
    interface HTMLElementTagNameMap {
        'ata-navigation': AtaNavigation;
    }
}

@customElement('ata-navigation')
export default class AtaNavigation extends LitElement {
    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            block-size: 100%;
            background: var(--ata--app--navigation--backgroundColor);
            container-name: navigation;
            container-type: inline-size;
        }

        ::slotted(ata-navigation-item),
        ::slotted(ata-navigation-item-with-sub) {
            border-top: 1px solid black;
            transition: opacity 200ms linear;
        }

        @container navigation (max-width: 20rem) {
            ::slotted(ata-navigation-item),
            ::slotted(ata-navigation-item-with-sub) {
                opacity: 0;
                pointer-events: none;
            }
        }
    `;

    render(): TemplateResult {
        return html`
            <slot name="header"></slot>

            <slot></slot>
        `;
    }
}
