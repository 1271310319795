import { DateRange } from '../DateRange';

export default {
    onInput,
    onChange,
    onCheck,
    onDateRangeChange,
    onLinkClick,
};

export function onLinkClick<T>(toMsg: (href: string) => T) {
    return (event: Event): T => {
        event.preventDefault();
        const element =
            event.target instanceof HTMLAnchorElement
                ? event.target
                : (event.target as HTMLElement).closest('a');
        return toMsg(element?.getAttribute('href') ?? '');
    };
}

export function onInput<T>(toMsg: (value: string) => T) {
    return (event: InputEvent): T => {
        event.preventDefault();
        return toMsg((event.target as HTMLInputElement)!.value);
    };
}

export function onChange<T>(toMsg: (value: string) => T) {
    return (event: Event): T => {
        event.preventDefault();
        return toMsg((event.target as HTMLInputElement)!.value);
    };
}

export function onCheck<T>(toMsg: (checked: boolean) => T) {
    return (event: Event): T => {
        event.preventDefault();
        return toMsg((event.target as HTMLInputElement)!.checked);
    };
}

export function onDateRangeChange<T>(
    toMsg: (value: { startDate: string; endDate: string }) => T,
) {
    return ([start, end]: DateRange): T => {
        return toMsg({
            startDate: start.toISOString().split('T')[0],
            endDate: end.toISOString().split('T')[0],
        });
    };
}

export function onSubmit<T>(toMsg: () => T) {
    return (event: Event): T => {
        event.preventDefault();
        return toMsg();
    };
}
