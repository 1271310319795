export function exhaustiveCheck(value: never): never {
    throw new Error(
        `Unhandled member of a union type.
        
You need to account for the following values:
        
    ${JSON.stringify(value, null, 2).replace(/\n/g, '\n\t')}


Tip: This error often occurs if a new variant was recently added to a union type but hasn't been handled everywhere it's used.
`,
    );
}
