export type Route =
    | { id: 'NotFound' }
    | { id: 'AnalysisCostBenefit' }
    | { id: 'AnalysisLeadPerformance' }
    | { id: 'AnalysisMakeModel' }
    | { id: 'AnalysisOverview' }
    | { id: 'ComparisonAdvicePrice' }
    | { id: 'ComparisonDaysInStock' }
    | { id: 'ComparisonGeographic' }
    | { id: 'ComparisonOverview' }
    | { id: 'Dashboard' }
    | { id: 'Leads' }
    | { id: 'Login' }
    | { id: 'Logout' }
    | { id: 'Prospects' }
    | { id: 'Reach' }
    | { id: 'SettingsBranches' }
    | { id: 'SettingsCompetingCompanies' }
    | {
          id: 'SettingsCompetingCompaniesProfile';
          params: { profileId: string | null };
      }
    | { id: 'SettingsCompetingVehicles' }
    | { id: 'SettingsNotifications' }
    | { id: 'SettingsScenarios' }
    | { id: 'SettingsScenario'; params: { scenarioId: string | null } }
    | { id: 'SettingsStock' }
    | { id: 'Stock' }
    | { id: 'TaxationDashboard'; params: { vehicleId: string } }
    | { id: 'TaxationComparisonOverview'; params: { vehicleId: string } }
    | { id: 'TaxationComparisonPriceAnalysis'; params: { vehicleId: string } }
    | { id: 'TaxationComparisonPriceHistory'; params: { vehicleId: string } }
    | { id: 'TaxationComparisonGeographic'; params: { vehicleId: string } }
    | { id: 'TaxationSettings'; params: { vehicleId: string } }
    | { id: 'TaxationSearch' }
    | { id: 'VehicleComparisonGeographic'; params: { vehicleId: string } }
    | { id: 'VehicleComparisonOverview'; params: { vehicleId: string } }
    | { id: 'VehicleComparisonPriceAnalysis'; params: { vehicleId: string } }
    | { id: 'VehicleComparisonPriceHistory'; params: { vehicleId: string } }
    | { id: 'VehicleDashboard'; params: { vehicleId: string } }
    | { id: 'VehicleReachOverview'; params: { vehicleId: string } }
    | { id: 'VehicleReachPriceEffect'; params: { vehicleId: string } }
    | { id: 'VehicleSettings'; params: { vehicleId: string } };

type OfUnion<T extends { id: string }> = {
    [P in T['id']]: T extends { id: P; params: infer Params }
        ? (payload: Params) => Extract<T, { id: P; params: Params }>
        : () => Extract<T, { id: P }>;
};

export const Route: OfUnion<Route> = {
    NotFound: () => ({
        id: 'NotFound',
    }),
    AnalysisCostBenefit: () => ({
        id: 'AnalysisCostBenefit',
    }),
    AnalysisLeadPerformance: () => ({
        id: 'AnalysisLeadPerformance',
    }),
    AnalysisMakeModel: () => ({
        id: 'AnalysisMakeModel',
    }),
    AnalysisOverview: () => ({
        id: 'AnalysisOverview',
    }),
    ComparisonAdvicePrice: () => ({
        id: 'ComparisonAdvicePrice',
    }),
    ComparisonDaysInStock: () => ({
        id: 'ComparisonDaysInStock',
    }),
    ComparisonGeographic: () => ({
        id: 'ComparisonGeographic',
    }),
    ComparisonOverview: () => ({
        id: 'ComparisonOverview',
    }),
    Dashboard: () => ({
        id: 'Dashboard',
    }),
    Leads: () => ({
        id: 'Leads',
    }),
    Login: () => ({
        id: 'Login',
    }),
    Logout: () => ({
        id: 'Logout',
    }),
    Prospects: () => ({
        id: 'Prospects',
    }),
    Reach: () => ({
        id: 'Reach',
    }),
    SettingsBranches: () => ({
        id: 'SettingsBranches',
    }),
    SettingsCompetingCompanies: () => ({
        id: 'SettingsCompetingCompanies',
    }),
    SettingsCompetingCompaniesProfile: (params) => ({
        id: 'SettingsCompetingCompaniesProfile',
        params,
    }),
    SettingsCompetingVehicles: () => ({
        id: 'SettingsCompetingVehicles',
    }),
    SettingsNotifications: () => ({
        id: 'SettingsNotifications',
    }),
    SettingsScenarios: () => ({
        id: 'SettingsScenarios',
    }),
    SettingsScenario: (params) => ({
        id: 'SettingsScenario',
        params,
    }),
    SettingsStock: () => ({
        id: 'SettingsStock',
    }),
    Stock: () => ({
        id: 'Stock',
    }),
    TaxationDashboard: (params) => ({
        id: 'TaxationDashboard',
        params,
    }),
    TaxationComparisonGeographic: (params) => ({
        id: 'TaxationComparisonGeographic',
        params,
    }),
    TaxationComparisonOverview: (params) => ({
        id: 'TaxationComparisonOverview',
        params,
    }),
    TaxationComparisonPriceAnalysis: (params) => ({
        id: 'TaxationComparisonPriceAnalysis',
        params,
    }),
    TaxationComparisonPriceHistory: (params) => ({
        id: 'TaxationComparisonPriceHistory',
        params,
    }),
    TaxationSettings: (params) => ({
        id: 'TaxationSettings',
        params,
    }),
    TaxationSearch: () => ({
        id: 'TaxationSearch',
    }),
    VehicleComparisonGeographic: (params) => ({
        id: 'VehicleComparisonGeographic',
        params,
    }),
    VehicleComparisonOverview: (params) => ({
        id: 'VehicleComparisonOverview',
        params,
    }),
    VehicleComparisonPriceAnalysis: (params) => ({
        id: 'VehicleComparisonPriceAnalysis',
        params,
    }),
    VehicleComparisonPriceHistory: (params) => ({
        id: 'VehicleComparisonPriceHistory',
        params,
    }),
    VehicleDashboard: (params) => ({
        id: 'VehicleDashboard',
        params,
    }),
    VehicleReachOverview: (params) => ({
        id: 'VehicleReachOverview',
        params,
    }),
    VehicleReachPriceEffect: (params) => ({
        id: 'VehicleReachPriceEffect',
        params,
    }),
    VehicleSettings: (params) => ({
        id: 'VehicleSettings',
        params,
    }),
};
